import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Text
} from 'react-native'
import { LicenseInfo } from '@mui/x-license-pro';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SupportTicketsDataGrid from './SupportTicketsDataGrid';

const SupportTicketsTable = (props) => {

    const theme = props.theme
    const newCases = props.newCases
    const openCases = props.openCases
    const awaitingCases = props.awaitingCases
    const resolvedCases = props.resolvedCases
    const backlogCases = props.backlogCases
    const backlogCasesCount = props.backlogCasesCount
    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

    const backlogLabel = `Backlog Cases (${backlogCasesCount})`

    return (
        <View style={styles.container}>
            <AccordionComponent
                theme={theme}
                data={newCases}
                title={'New Cases (Updated every hour)'}
                expanded={true}
            />
            <AccordionComponent
                theme={theme}
                data={openCases}
                title={'Open Cases'}
                expanded={true}
            />
            <AccordionComponent
                theme={theme}
                data={awaitingCases}
                title={'Awaiting Cases'}
                expanded={true}
            />
            <AccordionComponent
                theme={theme}
                data={resolvedCases}
                title={'Resolved Cases'}
                expanded={true}
            />
            <AccordionComponent
                theme={theme}
                data={backlogCases}
                title={backlogLabel}
                expanded={true}
            />


        </View>
    )

}

const NoDataComponent = ({theme}) => {
    return (
        <View style={{width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <Text style={{fontSize: 20, color: theme.color, fontFamily: 'Electrolize'}}>
                No Data
            </Text>
        </View>
    )
}

const AccordionComponent = ({ theme, data, title, expanded }) => {

    const renderComponent = () => {

        if (data.length === 0) {
            return <NoDataComponent theme={theme}/>
        } else {
            return <SupportTicketsDataGrid
            theme={theme}
            dataProp={data}
         />
        }
    }

    return (
        < >
            <Accordion style={{marginTop: 10}}>
                <AccordionSummary
                
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }}/>}
                aria-controls={`${title}-content`}
                id={`${title}-header`}
                sx={{
                    color: 'white',
                    backgroundColor: theme.backgroundColor2,
                    fontFamily: 'Electrolize',
                    fontWeight: 'bold'
                }}
                >
                {title}
                </AccordionSummary>
                <AccordionDetails sx={{backgroundColor: theme.backgroundColor, borderColor: theme.color, borderWidth: 1}}>
                 {renderComponent()}
                </AccordionDetails>
            </Accordion>
        </>
    );
};

export default SupportTicketsTable

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        overflow: 'auto',
        padding: '1%'
    }
})