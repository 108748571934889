import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../../Components/Loader.js';
import OptionsGroupingCubeData from './OptionsGroupingCubeData.js';
import OptionsGroupingPanels from './OptionsGroupingPanels.js';

const windowHeight = Dimensions.get('window').height;

const OptionsGroupingPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const configArray = props.configArray
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayPlus2 = yesterday2.clone().add(0, 'hour');
    const yesterdayProp = yesterdayPlus2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_adgroup";

    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(30, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'campaign'
    const filter4name = 'adgroup'
    const filter5name = 'ps_channel'
    const filter6name = 'ps_sub_channel'

    const start = firstDayOfYesterdaysMonthProp
    const end = yesterdayProp
    

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    

    const cubeFilters = [
 
    ]

    const cubeQuery = OptionsGroupingCubeData(cubeTable, start, end, filter1name, filter2name, filter3name, filter4name, filter5name, filter6name, cubeFilters, client)
    const { resultSet } = useCubeQuery(cubeQuery);
    
    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = cubeResponse?.[0]?.[updateVar] || 'loading...';

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <OptionsGroupingPanels
                    theme={theme}
                    updateArray={updateArray}
                    currentMetrics={currentMetrics}
                    configArray={configArray}
                />

            </View>
        </View>

    )

}

export default OptionsGroupingPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})