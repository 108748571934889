export default function OptionsGroupingCubeData (table, start, end, dim1, dim2, dim3, dim4, dim5, dim6, filters, client) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.spend`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.ftd_ngr`
        ],
        "dimensions": [
        `${table}.${dim1}`,
        `${table}.${dim2}`,
        `${table}.${dim3}`,
        `${table}.${dim4}`,
        `${table}.updated_at`,
        ],
        "limit": 2000,
        "filters": filters
    }

}