import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import PPCPanels from '../PPC/PPCPanels';
import LoadingComponent from '../Components/Loader';
import { useCubeQuery } from '@cubejs-client/react';
import Dimensions_4 from '../CubeFunctions/Dimensions_4.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import CubePPC from '../PPC/CubeFunctions/CubePPC.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const SocialPanelAdGroup = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "PPC Campaigns";
    const clientID = props.clientID
    const filterList = props.filterList
    const setFilterList = props.setFilterList

    let currentChannel;

    if (clientID === 'Glitnor') {
        currentChannel = ['Meta Ads']
    } else {currentChannel = ["Paid Social"]}

    const client = clientID.toLowerCase();
    const cubeTable = client + "_adgroup";

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const startDateString = previousDate.toISOString().slice(0, 10);

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'kw_type'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (clientID === 'Glitnor' && filters[filter2name] === 'Total') {
        filter2Filter = ['Meta Ads']
        filter2Operator = ['equals']
    } else if (clientID === 'PS' && filters[filter2name] === 'Total') {
        filter2Filter = ['Meta Ads']
        filter2Operator = ['equals']
    } else if (clientID === 'Rank' && filters[filter2name] === 'Total') {
        filter2Filter = ['Paid Social']
        filter2Operator = ['equals']
    } else if (clientID === 'Gamdom' && filters[filter2name] === 'Total') {
        filter2Filter = ['Facebook - Acquisition']
        filter2Operator = ['equals']
    } else if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }



    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };
    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
          };
  
        setFilters(filterUpdate)
  
      };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        clicks: false,
        impressions: false,
        regs: false,
        channel: false,
        cpr: false
      });

    const cubeFilters = [
        {
            "member": `${cubeTable}.${filter1name}`,
            "operator": `${filter1Operator}`,
            "values": filter1Filter
        },
        {
            "member": `${cubeTable}.${filter2name}`,
            //"operator": `${filter2Operator}`,
            "operator" : `${filter2Operator}`,
            "values": filter2Filter
        },
        {
            "member": `${cubeTable}.${filter3name}`,
            "operator": `${filter3Operator}`,
            "values": filter3Filter
        }
    ]
  
  
    const cubeQuery = CubePPC(cubeFilters, cubeTable, start, end, 'adgroup')
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = Dimensions_4(cubeTable, start, end, filter1name, filter2name, filter3name, filter2Filter, filter2Operator);
    const dimResult = useCubeQuery(dimQuery)
  
    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }
  
    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }
  
  
    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name);
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name);
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTable, filter3name);
  
    //const agg_result = ParseBudget(cubeResponse, cubeTable, 'graph')
    const agg_result = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = dimData?.[0]?.[updateVar] || 'loading...';

    if (agg_result.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    const agg_result_final = agg_result.map((obj) => {
        let adgroup;

        function checkInfinite (value) {
            let output;
            if (!isFinite(value)) {
                output = 0
            } else { output = value}
            return output
        }
        
        if ((obj.adgroup).length > 50) {
            adgroup = (obj.adgroup).slice(0,50)+"..."
        } else { adgroup = obj.adgroup}
        const ftds = obj.ftds;
        const regs = obj.regs;
        const ngr = obj.ftd_ngr;
        const spend = parseFloat((obj.spend));
        const cpa = ftds === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        const cpr = regs === 0 || spend === 0 || isNaN(regs) ? 0 : spend / regs;
        const roas = ngr === 0 || spend === 0 || isNaN(spend) ? 0 : ngr / spend;
        return {
            ...obj,
            spend: spend,
            ftds: parseFloat(ftds),
            cpa: checkInfinite(cpa.toFixed(2)) || 0,
            cpr: checkInfinite(cpr.toFixed(2)) || 0,
            roas: checkInfinite(roas.toFixed(6)) || 0,
            adgroup: adgroup
        };
    });

    const dataProp = {
        aggResult : agg_result,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
    }

    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>
            <PPCPanels
                style={{flex : 1 }}
                theme={theme}
                dataProp={agg_result_final}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                agg_result={agg_result}
                currentLevel={agg_result_final}
                currentChannel={currentChannel}
                filterParams={filterParams}
                dataPropChannel={dataProp}
                setFilterList={setFilterList}
                filterList={filterList}
                currentMetrics={currentMetrics}
                columnVisibilityModel={columnVisibilityModel}
                setColumnVisibilityModel={setColumnVisibilityModel}
            />
            </View>           
        </View>

    )

}

export default SocialPanelAdGroup

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})