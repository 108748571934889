import React from 'react';
import { Modal, 
    View,
    Text,
    StyleSheet, 
    Dimensions, 
    Pressable } from 'react-native';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const MobileModalClient = (props) => {

    const modalClientHandler = props.modalClientHandler
    const visibleClientModal = props.visibleClientModal
    const clientHandler = props.clientHandler
    const screenHandler = props.screenHandler
    const theme = props.theme


    return (
        <Modal
            visible={visibleClientModal}
            style={styles.modalParent}
            transparent={true}
            >
        <Pressable 
            style={styles.modalContainer}
            onPress={() => modalClientHandler(false)}
            >
            <View style={[styles.modalWindow , {
                backgroundColor : theme.backgroundColor2
                }]}>

                <OptionsModalClient 
                    style={{flex: 1}}
                    theme={theme}
                    clientHandler={clientHandler}
                    modalClientHandler={modalClientHandler}
                    screenHandler={screenHandler}
                    >
                    

                </OptionsModalClient>

            </View>


        </Pressable>


        </Modal>
    )

}

const OptionsModalClient = (props) => {

    const theme = props.theme
    const clientHandler = props.clientHandler
    const modalClientHandler = props.modalClientHandler
    const responsiveText = windowWidth * 0.04
    const screenHandler = props.screenHandler

    function clickHandler (clientId, clientMapId) {
        modalClientHandler(false)
        clientHandler(clientId, clientMapId)
        screenHandler('MobileHome')
    }

    const textTheme = {
        color: theme.color2,
        fontSize: responsiveText,
        textAlign: 'center',
        fontFamily: 'Electrolize',
        fontWeight: 600
    }

    const rowChild = {
        height: '100%',
        width: '50%',
        flexDirection: 'column',
        borderColor: theme.color2,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }

    return (
        <View style={styles.container}>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {borderTopLeftRadius:10}]}
                    onPress={() => clickHandler("FM", "1006")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                        Fortunate Media
                        </Text>
                    </View>
                </Pressable>

                <Pressable 
                    style={[rowChild , {borderTopRightRadius:10}]}
                    onPress={() => clickHandler("Gamdom", "1002")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Gamdom
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {}]}
                    onPress={() => clickHandler("Glitnor", "1003")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Glitnor
                        </Text>
                    </View>
                </Pressable>

                <Pressable 
                    style={[rowChild , {}]}
                    onPress={() => clickHandler("Hero", "1005")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Hero
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {}]}
                    onPress={() => clickHandler("PS", "1007")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Pepperstone
                        </Text>
                    </View>
                </Pressable>
                <Pressable 
                    style={[rowChild , {}]}
                    onPress={() => clickHandler("Rank", "1004")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                        Rank International
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {borderBottomLeftRadius:10}]}
                    onPress={() => clickHandler("Test", "1001")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Test Data
                        </Text>
                    </View>
                </Pressable>
            </View>
        </View>
    )

}

const styles = StyleSheet.create({
    modalParent: {
        padding: 20,
        borderRadius: 20,
    },
    modalContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: windowWidth * 1,
        height: windowHeight * 1,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
    },
    modalWindow: {
        margin: 10,
        height: '50%',
        width: '90%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 15,
        padding: '1%',
        alignItems: 'center',
        justiftContent: 'center'
    },
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 10,
        flexDirection: 'column',
    },
    rowParent: {
        height: '25%',
        width: '100%',
        flexDirection: 'row'
    },
    childIcon: {
        width: '100%',
        height: '80%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    childText: {
        width: '100%',
        height: '20%',
        justifyContent: 'center',
        alignItems: 'center',
    }
});


export default MobileModalClient