export default function ProgCubeQuery (cubeFilters, table, start, end, cubeDim) {

        return {
            "timeDimensions": [
              {
                "dimension": `${table}.timestamp`,
                "dateRange": [
                    start,
                    end
                ]
              }
            ],
            "order": [
              [
                `${table}.regs`,
                "desc"
              ]
            ],
            "measures": [
                `${table}.impressions`,
                `${table}.clicks`,
                `${table}.cost`,
                `${table}.regs`,
                `${table}.ftds`,
                `${table}.ngr`
            ],
            "dimensions": [
                `${table}.${cubeDim}`
            ],
            "limit": 10000,
            "filters": cubeFilters
          }
        

    }