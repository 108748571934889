import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { 
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridToolbarExport 
   } from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import { DataGridPremium,
    GRID_AGGREGATION_FUNCTIONS, } from '@mui/x-data-grid-premium';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function AnalyticsEnhancedDataGrid(props) {

    const currentMetrics = props.currentMetrics
    const dataProp = props.dataProp
    const theme = props.theme
    const client = props.client
    console.log(dataProp)

    const [aggregationModel, setAggregationModel] = useState({
        new_users: 'sum',
        sessions: 'sum',
        pageviews: 'sum',
        regs: 'sum',
        ftds: 'sum',
        ftd_ngr : 'sum',
        retention_ngr: 'sum',
        deposits: 'sum',
        deposit_amount: 'sum',
        aff_crossover: 'sum',
        reg_start: 'sum',
        entry_page: 'total',
        convRate: 'convRate',
        affCrossoverRate: 'crossRate'
      });

    let responseText;

    if (windowWidth < 1281) {
        responseText = windowHeight * 0.025
    } else if (windowWidth > 2000) {
        responseText = windowHeight * 0.018
    } else {responseText = windowHeight * 0.017}

    let responseRow;

    if (windowWidth < 1281) {
      responseRow = windowHeight * 0.05
    } else if (windowWidth > 2000) {
      responseRow = windowHeight * 0.05
    } else {responseRow = windowHeight * 0.04}

    let responsePad;

    if (windowWidth < 1281) {
      responsePad = '0%'
    } else if (windowWidth > 2000) {
      responsePad = '5%'
    } else {responsePad = '0%'}


    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
        id: false,
        new_users: true,
        sessions: true,
        pageviews: true,
        regs: false,
        ftds: true,
        ftd_ngr : false,
        retention_ngr: true,
        deposits: false,
        deposit_amount: false,
        aff_crossover: false,
        entry_page: true,
        convRate: true,
        affCrossover: true
    });

    const formatValue = (value) => {
        return parseFloat(value).toLocaleString('en-UK', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const formatValueZero = (value) => {
        return parseFloat(value).toLocaleString('en-UK', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    const valueFormat = (value, field) => {
        if (field === 'ftd_ngr' || field === 'retention_ngr' || field === 'deposit_amount') {
            const formatOutput = formatValue(value)
            console.log(value)
            return `€${formatOutput}`;
        } else if (field === 'convRate' || field === 'affCrossoverRate') {
            const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
            return `${percent}%`;
        } else {
            return formatValueZero(value);
        }
        
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Electrolize']
            }
        });
    }, []);

    let uniqueIdCounter = 1;

    // Use forEach to add a unique ID to each record
    dataProp.forEach((record) => {
        record.id = uniqueIdCounter++;
    });

    const labels = Object.keys(dataProp[0]).map((key, index) => {

        let newLabel;
        if (key === 'ftds' && client !== 'glitnor') {
            newLabel = currentMetrics.primaryMetric
        } else if (key === 'ftd' && client !== 'glitnor') {
            newLabel = currentMetrics.primaryMetric
        }else if (key === 'regs') {
            newLabel = currentMetrics.secondaryMetric
        } else if (key === 'kw_type') {
            newLabel = currentMetrics.primaryDimensionString.toLowerCase()
        } else {newLabel = key}

        return {
            field: key,
            headerName: newLabel,
            //minWidth: windowWidth * columnWidth * 1.25,
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'table-header',
            align: 'center',
            type: 'number',
            renderCell: (params) => {
                const { value, field } = params;
                const heatmapColor = calculateHeatmapColor(value, { field });
                console.log(params)
                const textColour = (heatmap) => {
                    let colourOuput;
                    if (heatmap.includes('NaN')) {
                        colourOuput = theme.color
                    } else {
                        colourOuput = 'white'
                    }
                    return colourOuput
                }
                if (index <= 0) {
                    return (
                        <div
                            className="heatmap-cell"
                            style={{
                                backgroundColor: heatmapColor,
                                padding: '10%',
                                color: theme.color,
                                width: '100%',
                                fontSize: responseText * 0.8
                            }}
                        >
                            {value}
                        </div>
                    );
                } else if (params.aggregation) {
                    return (
                        <div
                            className="heatmap-cell-aggregated"
                            style={{
                                fontWeight: 'bold', // Add your custom font weight
                                display: 'flex', // Apply flex styles to center content vertically/horizontally
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%', // Ensure the cell takes up the full height
                                width: '100%',
                                color: theme.color,
                                paddingLeft: 0,
                                fontFamily: 'Electrolize',
                                fontSize: responseText * 0.8,
                            }}
                        >
                            {valueFormat(params.value, params.field)}
                        </div>
                    );
                } else return (
                    <div
                        className="heatmap-cell"
                        style={{
                            backgroundColor: heatmapColor,
                            padding: '10%',
                            width: '100%',
                            fontSize: responseText * 0.8,
                            color: textColour(heatmapColor)

                        }}
                    >
                        {valueFormat(params.value, params.field)}
                    </div>
                );
            },

        };
    });

    const columnRanges = {};
    Object.keys(dataProp[0]).forEach((column) => {
        const values = dataProp.map((record) => record[column]);
        const min = Math.min(...values);
        const max = Math.max(...values);
        columnRanges[column] = { min, max };
    });

    function calculateHeatmapColor(value, column) {
        const { min, max } = columnRanges[column.field];
        const range = max - min;

        // Define input colors for the heatmap in RGB format
        const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
        const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };;
      


        const relativeValue = (value - min) / range;
        const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
        const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
        const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

        return `rgba(${red},${green},${blue},0.7)`;
    }

    function CustomToolbar() {
        return (
          <GridToolbarContainer sx={{color: theme.color}}>
            <GridToolbarColumnsButton sx={{color: theme.color}}/>
              <GridToolbarFilterButton sx={{color: theme.color}}/>
              <GridToolbarDensitySelector sx={{color: theme.color}}/>
              <GridToolbarExport sx={{color: theme.color}}/>
          </GridToolbarContainer>
        );
      }

    function calculateCPA (spend, ftd) {
    const cpa =  (spend / ftd)

    return Math.round(cpa)
    }

    function calculateConvRate (ftd, new_users) {
        const convRate =  (ftd / new_users)
    
        return convRate
        }

    function calculateCrossRate (ftd, aff) {
        const convRate =  (aff / ftd)
    
        return convRate
        }

    function calculateTotal (spend, ftd) {
        const cpa =  (spend / ftd)
    
        return "TOTAL"
        }

    const convRate = {
        label: '',
        getCellValue: ({ row }) => ({ ftds: row.ftds, new_users: row.new_users }),
        apply: ({ values }) => {
            let ftds = 0;
            let new_users = 0;
            values.forEach((value) => {
            if (value) {
                ftds += value.ftds || 0; // Adds 0 if value.spend is null or NaN
                new_users += value.new_users || 0;
            }
            });
            return calculateConvRate(ftds, new_users);
        },
        columnTypes: ['number'],
        };

    const crossRate = {
        label: '',
        getCellValue: ({ row }) => ({ ftds: row.ftds, aff_crossover: row.aff_crossover }),
        apply: ({ values }) => {
            let ftds = 0;
            let aff_crossover = 0;
            values.forEach((value) => {
            if (value) {
                ftds += value.ftds || 0; // Adds 0 if value.spend is null or NaN
                aff_crossover += value.aff_crossover || 0;
            }
            });
            return calculateCrossRate(ftds, aff_crossover);
        },
        columnTypes: ['number'],
        };

    const cpa = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
        apply: ({ values }) => {
          let spend = 0;
          let ftd = 0;
          values.forEach((value) => {
            if (value) {
                spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                ftd += value.ftd || 0;
            }
          });
          return calculateCPA(spend, ftd);
        },
        columnTypes: ['number'],
      };

      const total = {
        label: '',
        getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
        apply: ({ values }) => {
          let spend = 0;
          let ftd = 0;
          values.forEach((value) => {
            if (value) {
                spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
                ftd += value.ftd || 0;
            }
          });
          return calculateTotal(spend, ftd);
        },
        columnTypes: ['number'],
      };

    const rowCount = parseInt(windowWidth / 120)

    return (
        <Box
            style={{
                width: '100%',
                height: windowHeight * 0.849,
                fontSize: 50,
                color: 'black',
                fontFamily: 'Electrolize'

            }}
            sx={{
                '& .table-header': { 
                headerAlign: 'center', 
                align : 'center' , 
                fontFamily : 'Electrolize' , 
                color: theme.color, 
                fontSize: responseText, },
                '& .MuiDataGrid-row' : {backgroundColor : theme.backgroundColor},
                width: '100%'
            }}
        >
            <DataGridPremium
                rows={dataProp}
                columns={labels}
                rowHeight={responseRow}
                pagination
                slots={{ toolbar: CustomToolbar  }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                aggregationModel={aggregationModel}
                onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
                aggregationFunctions={{
                ...GRID_AGGREGATION_FUNCTIONS,
                cpa,
                total,
                convRate,
                crossRate
                }}
                
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: rowCount, page: 0 }
                    },
                    
                    sorting: {
                        sortModel: [{ field: 'ftds', sort: 'desc' }],
                    },
                }}

            />
        </Box>
    );
}