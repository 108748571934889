import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import SupportTicketCube from './SupportTicketCube.js';
import SupportTicketsParse from './SupportTicketsParse.js';
import SupportTicketsPanels from './SupportTicketsPanels.js';

const windowHeight = Dimensions.get('window').height;

const SupportTicketsPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const userNickname = configArray.userNickname
    const clientID = configArray.clientID
    const cubeTable = 'pattern_cases_viz'

    const externalFilter = ['External']
    const internalFilter = ['Internal', 'External']
    let sourceFilter;
    if (userNickname === 'adam' || userNickname === 'joao' || userNickname === 'pedro') {
        sourceFilter = internalFilter
    } else {
        sourceFilter = externalFilter
    }
    
    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.client`,
            "operator": 'equals',
            "values": [clientID]
        },
        {
            "member": `${cubeTable}.user_type`,
            "operator": 'equals',
            "values": sourceFilter
        }
    ]

    const cubeQuery = SupportTicketCube(cubeFilters, cubeTable)
    const { resultSet } = useCubeQuery(cubeQuery);

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    const cubeResponse = resultSet.loadResponse.results[0].data

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const supportData = SupportTicketsParse(updateArray)

    return (
        <View style={styles.container}>
            <SupportTicketsPanels
                theme={theme}
                configArray={configArray}
                supportData={supportData}
            />

        </View>
    )

}

export default SupportTicketsPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.93,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    }
})