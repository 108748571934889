import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import Button from '@mui/material/Button';
import { postToSignedUrl } from './OptionsGroupingGET';
import signRequest from './OptionsGroupingGET';
import OptionsGroupingLoader from './OptionsGroupingLoader';
import OptionsGroupingDefinitionsView from './OptionsGroupingDefinitionsView';


const windowWidth = Dimensions.get('window').width;

const OptionsGroupingDefinitions = (props) => {

    const theme = props.theme
    const updateArray = props.updateArray
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    const client = configArray.clientID
    const clientMapId = configArray.clientMapId
    const support_url = 'https://kp37hynn7pvwh257afbo3hefnm0bfyhe.lambda-url.eu-central-1.on.aws/'
    const support_key = configArray.support_key
    const support_id = configArray.support_id
    const [loading, setLoading] = useState(false);
    const [groupingData, setGroupingData] = useState([])


    const sendDataToLambda = async (event_data, support_url, support_key, support_id, method) => {
        setLoading(true); // Start loading
        setGroupingData([]); // Clear previous response

        try {
            // First, get the signed URL
            const signedUrl = await signRequest(event_data, support_url, support_key, support_id, method);

            // Send the POST request to the signed URL
            const result = await postToSignedUrl(signedUrl, event_data, method);
            setGroupingData(result)
            setLoading(false);
        } catch (error) {
            console.error("Error posting to Lambda:", error);
        } finally {
            setLoading(false);
        }
    };

    const loadHandler = () => {
        const event_data = {
            client: client,
            client_id: clientMapId,
            "request_type": "custom_configs"
        }

        sendDataToLambda(event_data, support_url, support_key, support_id, 'POST')
    }

    

    return (
        <View style={styles.container}>
            <View style={styles.headerContainer}>
                <View style={{
                    width: '10%',
                    height: windowWidth * 0.03,
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Button
                        onClick={loadHandler}
                        variant="contained"
                        sx={{ fontWeight: 600, textAlign: 'center', fontFamily: 'Electrolize' }}
                    >
                        Load Definitions
                    </Button>
                </View>

            </View>
            <View style={styles.bodyContainer}>
                <OptionsGroupingDefinitionsView
                    theme={theme}
                    groupingData={groupingData}
                />
            </View>
            <OptionsGroupingLoader
                theme={theme}
                open={loading}
            />

        </View>
    )

}



const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    headerContainer: {
        width: '100%',
        height: '10%',
        borderWidth: 1,
        borderColor: 'grey',
        flexDirection: 'row',
    },
    bodyContainer: {
        width: '100%',
        height: '90%',
    }
})

export default OptionsGroupingDefinitions