export default function ExecSummaryChannelParse (aggResult, brand, country) {

    var dataset = aggResult

    if (!dataset || dataset.length === undefined) {
        return null;
    }

    function filterByBrand(data, brand) {
        if (brand === 'Total') {
            return data
        } else {
            return data.filter(item => item.brand === brand);
        }
        
    }

    function filterByBrandBrand(data, dim) {
        return data.filter(item => dim.includes(item.dim));
    }

    function filterByNonBrand(data, dim) {
        return data.filter(item => dim.includes(item.dim));
    }

    const brandFiltered = filterByBrand(dataset, brand)
    const brandBrandFiltered = filterByBrandBrand(brandFiltered, 'Brand')
    const nonBrandFiltered = filterByNonBrand(brandFiltered, 'Non-Brand')

    function reAggFunction (data, type, country) {

        function filterByType(data, dim) {
            if (dim === 'Total') {
                return data
            }
            return data.filter(item => dim.includes(item.type));
        }

        function filterByCountry(data, country) {
            if (country === 'Total') {
                return data
            }
            return data.filter(item => country.includes(item.country));
        }

        const typeFiltered = filterByType(data, type)
        
        const countryFiltered = filterByCountry(typeFiltered, country)

        const aggregatedData = countryFiltered.reduce((acc, item) => {
            const { minus2_day, minus3_day, mtd, prevMonth, yesterday } = item;
        
            acc.minus2_day += parseFloat(minus2_day) || 0;
            acc.minus3_day += parseFloat(minus3_day) || 0;
            acc.mtd += parseFloat(mtd) || 0;
            acc.prevMonth += parseInt(prevMonth) || 0;
            acc.yesterday += parseFloat(yesterday) || 0;
        
            return acc;
        }, {
            // Initial values set to 0 for each property
            minus2_day: 0,
            minus3_day: 0,
            mtd: 0,
            prevMonth: 0,
            yesterday: 0,
        });
        

        //console.log(aggregatedData)
        return aggregatedData

    }

    const brandAggFtd = reAggFunction (brandBrandFiltered, 'FTD', country)
    const brandAggSpend = reAggFunction (brandBrandFiltered, 'Spend', country)
    const brandAggClicks = reAggFunction (brandBrandFiltered, 'Clicks', country)
    const brandAggImps = reAggFunction (brandBrandFiltered, 'Impressions', country)
    const brandAggNgr = reAggFunction (brandBrandFiltered, 'NGR', country)
    

    const nonBrandAggFtd = reAggFunction (nonBrandFiltered, 'FTD', country)
    const nonBrandAggSpend = reAggFunction (nonBrandFiltered, 'Spend', country)
    const nonBrandAggClicks = reAggFunction (nonBrandFiltered, 'Clicks', country)
    const nonBrandAggImps = reAggFunction (nonBrandFiltered, 'Impressions', country)
    const nonBrandAggNgr = reAggFunction (nonBrandFiltered, 'NGR', country)

    const noFilterAggFtd = reAggFunction (brandFiltered, 'FTD', country)
    const noFilterAggSpend = reAggFunction (brandFiltered, 'Spend', country)
    const noFilterAggClicks = reAggFunction (brandFiltered, 'Clicks', country)
    const noFilterAggImps = reAggFunction (brandFiltered, 'Impressions', country)
    const noFilterAggNgr = reAggFunction (brandFiltered, 'NGR', country)

    console.log(noFilterAggFtd)
    
    const brandSpendYest = brandAggSpend['yesterday']
    const brandSpendMinus2 = brandAggSpend['minus2_day']
    const brandSpendMinus3 = brandAggSpend['minus3_day']
    const brandSpendMtd = brandAggSpend['mtd']
    const brandSpendPrevMonth = brandAggSpend['prevMonth']

    const nonBrandSpendYest = nonBrandAggSpend['yesterday']
    const nonBrandSpendMinus2 = nonBrandAggSpend['minus2_day']
    const nonBrandSpendMinus3 = nonBrandAggSpend['minus3_day']
    const nonBrandSpendMtd = nonBrandAggSpend['mtd']
    const nonBrandSpendPrevMonth = nonBrandAggSpend['prevMonth']

    const brandFtdYest = brandAggFtd['yesterday']
    const brandFtdMinus2 = brandAggFtd['minus2_day']
    const brandFtdMinus3 = brandAggFtd['minus3_day']
    const brandFtdMtd = brandAggFtd['mtd']
    const brandFtdPrevMonth = brandAggFtd['prevMonth']

    const nonBrandFtdYest = nonBrandAggFtd['yesterday']
    const nonBrandFtdMinus2 = nonBrandAggFtd['minus2_day']
    const nonBrandFtdMinus3 = nonBrandAggFtd['minus3_day']
    const nonBrandFtdMtd = nonBrandAggFtd['mtd']
    const nonBrandFtdPrevMonth = nonBrandAggFtd['prevMonth']

    const totalFtdYest = noFilterAggFtd['yesterday']
    const totalFtdMinus2 = noFilterAggFtd['minus2_day']
    const totalFtdMinus3 = noFilterAggFtd['minus3_day']
    const totalFtdMtd = noFilterAggFtd['mtd']
    const totalFtdPrevMonth = noFilterAggFtd['prevMonth']

    const totalSpendYest = noFilterAggSpend['yesterday']
    const totalSpendMinus2 = noFilterAggSpend['minus2_day']
    const totalSpendMinus3 = noFilterAggSpend['minus3_day']
    const totalSpendMtd = noFilterAggSpend['mtd']
    const totalSpendPrevMonth = noFilterAggSpend['prevMonth']

    const totalImpsYest = noFilterAggImps['yesterday']
    const totalImpsMinus2 = noFilterAggImps['minus2_day']
    const totalImpsMinus3 = noFilterAggImps['minus3_day']
    const totalImpsMtd = noFilterAggImps['mtd']
    const totalImpsPrevMonth = noFilterAggImps['prevMonth']

    const totalClicksYest = noFilterAggClicks['yesterday']
    const totalClicksMinus2 = noFilterAggClicks['minus2_day']
    const totalClicksMinus3 = noFilterAggClicks['minus3_day']
    const totalClicksMtd = noFilterAggClicks['mtd']
    const totalClicksPrevMonth = noFilterAggClicks['prevMonth']

    const totalNgrYest = noFilterAggNgr['yesterday']
    const totalNgrMinus2 = noFilterAggNgr['minus2_day']
    const totalNgrMinus3 = noFilterAggNgr['minus3_day']
    const totalNgrMtd = noFilterAggNgr['mtd']
    const totalNgrPrevMonth = noFilterAggNgr['prevMonth']

    const cpaBrand = {
        dim: 'CPA - Brand',
        yesterday: (brandSpendYest || 0) / (brandFtdYest || 0),
        minus2_day: (brandSpendMinus2 || 0) / (brandFtdMinus2 || 0),
        minus3_day: (brandSpendMinus3 || 0) / (brandFtdMinus3 || 0),
        mtd: (brandSpendMtd || 0) / (brandFtdMtd || 0),
        prevMonth: (brandSpendPrevMonth || 0) / (brandFtdPrevMonth || 0)
    }

    const cpaNonBrand = {
        dim: 'CPA - Non-Brand',
        yesterday: (nonBrandSpendYest || 0) / (nonBrandFtdYest || 0),
        minus2_day: (nonBrandSpendMinus2 || 0) / (nonBrandFtdMinus2 || 0),
        minus3_day: (nonBrandSpendMinus3 || 0) / (nonBrandFtdMinus3 || 0),
        mtd: (nonBrandSpendMtd || 0) / (nonBrandFtdMtd || 0),
        prevMonth: (nonBrandSpendPrevMonth || 0) / (nonBrandFtdPrevMonth || 0)
    }

    const ftd = {
        dim: 'FTD',
        yesterday: totalFtdYest || 0,
        minus2_day: totalFtdMinus2 || 0,
        minus3_day: totalFtdMinus3 || 0,
        mtd: totalFtdMtd || 0,
        prevMonth: totalFtdPrevMonth || 0
    }

    const spend = {
        dim: 'Spend',
        yesterday: totalSpendYest || 0,
        minus2_day: totalSpendMinus2 || 0,
        minus3_day: totalSpendMinus3 || 0,
        mtd: totalSpendMtd || 0,
        prevMonth: totalSpendPrevMonth || 0
    }

    const cpc = {
        dim: 'CPC',
        yesterday: (totalSpendYest || 0) / (totalClicksYest || 0),
        minus2_day: (totalSpendMinus2 || 0) / (totalClicksMinus2 || 0),
        minus3_day: (totalSpendMinus3 || 0) / (totalClicksMinus3 || 0),
        mtd: (totalSpendMtd || 0) / (totalClicksMtd || 0),
        prevMonth: (totalSpendPrevMonth || 0) / (totalClicksPrevMonth || 0)
    }

    const cpm = {
        dim: 'CPM',
        yesterday: (totalSpendYest || 0) / ((totalImpsYest || 0) / 1000),
        minus2_day: (totalSpendMinus2 || 0) / ((totalImpsMinus2 || 0) / 1000),
        minus3_day: (totalSpendMinus3 || 0) / ((totalImpsMinus3 || 0) / 1000),
        mtd: (totalSpendMtd || 0) / ((totalImpsMtd || 0) / 1000),
        prevMonth: (totalSpendPrevMonth || 0) / ((totalImpsPrevMonth || 0) / 1000)
    }

    const ngr = {
        dim: 'NGR',
        yesterday: totalNgrYest || 0,
        minus2_day: totalNgrMinus2 || 0,
        minus3_day: totalNgrMinus3 || 0,
        mtd: totalNgrMtd || 0,
        prevMonth: totalNgrPrevMonth ||0
    }

    const aggOutput = [cpaBrand, cpaNonBrand, ftd, spend, cpc, cpm, ngr]

    

    return aggOutput

}