import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const windowWidth = Dimensions.get('window').width;

export default function CountryModal(props) {
    const open = props.open
    const setOpen = props.setOpen
    const currentMetrics = props.currentMetrics
    const modalArray = props.modalArray
    const configKeys = props.configKeys
    const setCurrentConfig = props.setCurrentConfig
    const theme = props.theme

    const currentType = modalArray.selectedComponent
    const currentDim = modalArray.currentDim
    const currentMetric = modalArray.currentMetric
    
  
    const [typeValue, setTypeValue] = useState(currentType)
    const [dimValue, setDimValue] = useState(currentDim)
    const [metric1Value, setMetric1Value] = useState(currentMetric)

    const textHandler = (value) => {
      let output;
      if (value === 'ftds') {
        output = currentMetrics.primaryMetric
      } else if (value === 'regs') {
        output = currentMetrics.secondaryMetric
      } else if (value === 'ngr') {
        output = currentMetrics.primaryRevenue
      } else {
        output = value
      }
      return output
    }    
    
    let typeDisabled, dimensionDisabled, metric1Disabled;
    //metric2Disabled

    if (typeValue === 'line') {
      typeDisabled = false;
      dimensionDisabled = false;
      metric1Disabled = false;
      //metric2Disabled = true
    } else if (typeValue === 'bar') {
      typeDisabled = false;
      dimensionDisabled = false;
      metric1Disabled = false;
      //metric2Disabled = true
    } else if (typeValue === 'mixed') {
      typeDisabled = false;
      dimensionDisabled = false;
      metric1Disabled = false;
      //metric2Disabled = false
    } else if (typeValue === 'table') {
      typeDisabled = false;
      dimensionDisabled = false;
      metric1Disabled = true;
      //metric2Disabled = true
    }
  
    const chartTypes = ['line', 'bar', 'table']
    const dimTypes = ['brand', 'channel', 'campaign', 'adgroup']
    const excludeMetrics = ['date', 'count', 'brand', 'channel', 'campaign', 'adgroup', 'country']
  
    
    const metricList = configKeys.filter((item) => {
      return !excludeMetrics.includes(item);
    });
  
    const chartOptions = chartTypes.map((item, index) => (
      <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
        {item}
      </MenuItem>
    ));
  
    const dimOptions = dimTypes.map((item, index) => (
      <MenuItem key={index} value={item}>
        {item}
      </MenuItem>
    ));
  
    const metricOptions = metricList.map((item, index) => (
      <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
        {textHandler(item)}
      </MenuItem>
    ));
  
    const handleClose = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpen(false);
      }
      console.log(reason)
    };
  
    function handleSave () {
      //setMetric(textValue)
  
      const currentState = {
          type: typeValue,
          dimension: dimValue,
          metric1: metric1Value,
      }
  
      setCurrentConfig(currentState)
  
  
      setOpen(false);
    }
  
  
  
  
    return (
      <View style={{
        width: windowWidth * 0.8, 
        borderColor: 'red', 
        borderWidth: 0,
        
        }}>
      <div>
        <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500} >
          <DialogTitle sx={{fontFamily: 'Electrolize'}}>Configure Chart</DialogTitle>
          <DialogContent>
            <Box component="form" sx={{ 
                                      display: 'flex', 
                                      flexDirection: 'row',
                                      }}>
                  <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
                  <InputLabel id="demo-dialog-select-label" sx={{fontFamily: 'Electrolize'}}>Chart Type</InputLabel>
                  <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={typeValue}
                      disabled={typeDisabled}
                      sx={{fontFamily: 'Electrolize'}}
                      onChange={(event) => setTypeValue(event.target.value)}
                      input={<OutlinedInput label="Chart Type" />}
                  >
                      {chartOptions}
                  </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
                  <InputLabel id="demo-dialog-select-label" sx={{ width: 200, fontFamily: 'Electrolize' }}>Metric 1</InputLabel>
                  <Select
                      labelId="demo-dialog-select-label"
                      id="demo-dialog-select"
                      value={metric1Value}
                      sx={{fontFamily: 'Electrolize'}}
                      disabled={metric1Disabled}
                      onChange={(event) => setMetric1Value(event.target.value)}
                      input={<OutlinedInput label="Metric 1" />}
                  >
                      {metricOptions}
                  </Select>
                  </FormControl>
            </Box>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} sx={{fontFamily: 'Electrolize'}}>Cancel</Button>
            <Button onClick={handleSave} sx={{fontFamily: 'Electrolize'}}>Update</Button>
          </DialogActions>
        </Dialog>
      </div>
      </View>
    );
  }