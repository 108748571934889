import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle';
import CountryFunction from './CountryFunction';
import Switch from '@mui/material/Switch';
import WebFont from 'webfontloader';
import TuneIcon from '@mui/icons-material/Tune';
import CountryModal from './CountryModal/CountryModal';
import CountrySummaryAgg from './CountrySummaryAgg';
import LineMultiple from '../ChartsDesktop/LineMultiple';
import BarStacked from '../ChartsDesktop/BarStacked';
import CountryTable from './CountryDataGrid';
import CountryMetrics from './CountryMetrics';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const CountrySummaryPanels = (props) => {

    const client = props.client
    const agg_daily_result = props.agg_daily
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const currentData = props.agg_daily
    const timeFrame = props.timeFrame
    const [checked, setChecked] = React.useState(false);
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const lastUpdated = props.lastUpdated
    const [isCumulative, setIsCumulative] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)

    const currentConfigBase = {
        type: 'line',
        dimension: 'date',
        metric1: 'ftds'
    }
    const [currentConfig, setCurrentConfig] = useState(currentConfigBase)

    const selectedComponent = currentConfig.type
    const currentDim = currentConfig.dimension
    const currentMetric = currentConfig.metric1

    const modalArray = {
        selectedComponent : currentConfig.type,
        currentDim: currentConfig.dimension,
        currentMetric: currentConfig.metric1,
    }

    const calcCumulative = (data) => {
        let cumuImps = 0, cumuClicks = 0, cumuSpend = 0, cumuRegs = 0, cumuFtds = 0, cumuNgr = 0;

        return data.map(item => {
            cumuImps += item.impressions;
            cumuClicks += item.clicks;
            cumuSpend += item.spend;
            cumuRegs += item.regs;
            cumuFtds += item.ftds;
            cumuNgr += item.ngr

            return {
                date: item.date,
                country: item.country,
                impressions: cumuImps,
                clicks: cumuClicks,
                spend: cumuSpend,
                regs: cumuRegs,
                ftds: cumuFtds,
                ngr: cumuNgr,
                ctr: cumuClicks > 0 ? cumuImps / cumuClicks : 0,
                cpc: cumuImps > 0 ? cumuClicks / cumuImps : 0,
                cpa: cumuFtds > 0 ? cumuSpend / cumuFtds : 0,
                cpr: cumuRegs > 0 ? cumuSpend / cumuRegs : 0,
                roas: cumuSpend > 0 ? cumuNgr / cumuSpend : 0,
            }
        })
    }

    const countryData = CountryFunction(currentData, client)

    const configKeys = Object.keys(countryData[0])

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const dateLabels = getUniqueValues(countryData, currentDim)
    const countryLabels = getUniqueValues(countryData, 'country')

    const aggResult = CountrySummaryAgg(countryData, currentDim, currentMetric, countryLabels)

    let dynamicData, cumuDataFrame = [];

    aggResult.forEach(country => {

        cumuDataFrame.push(calcCumulative(country))

    })

    if (!isCumulative) {
        dynamicData = aggResult
    } else {
        dynamicData = cumuDataFrame
    }

   

    const handleChange = (event) => {
        setChecked(event.target.checked);
        setIsCumulative(event.target.checked)
        
      };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const renderComponentContent = () => {
        if (selectedComponent === 'line') {
            return <LineMultiple
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric={currentMetric}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
        } else if (selectedComponent === 'bar') {
            return <BarStacked
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        theme={theme}
                        metric={currentMetric}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                    />
        } else if (selectedComponent === 'table') {
            return <CountryTable
                        dataProp={countryData}
                        theme={theme}
                        currentMetrics={currentMetrics}
                    />
        }  else {
            return null;
        }
    };


    const panelMain = {

        width: '100%',
        height: windowHeight * 0.8,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    let topValue, rightValue;

    if (countryLabels.length > 5 && windowWidth > 1800) {
        topValue = "10%"
        rightValue = "1%"
    } else if (countryLabels.length > 5 && windowWidth < 1800) {
        topValue = "13.5%"
        rightValue = "1%"
    } else if (windowWidth > 1800) {
        topValue = "8%"
        rightValue = "1%"
    } else if (windowWidth < 1800) {
        topValue = "12.5%"
        rightValue = "1%"
    }  else {
        topValue = "12.5%"
        rightValue = "1%"
    }


    const headerToggleContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const cumuText = {
        fontFamily: 'Electrolize',
    }
    

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            large={1}
                            
                        />
                    </View>
                    <View style={headerToggleContainer}>
                        
                    <Text style={cumuText}>Cumulative:</Text>
                    <Switch
                        checked={checked}
                        onChange={handleChange}
                        sx={{
                            color: 'red'
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                        />
                    <Pressable 
                        style={{margin: 10}}
                        onPress={() => setModalVisible(true)}
                        >
                        <TuneIcon
                            sx={{color: theme.backgroundColor2}}
                        />
                    </Pressable>
                    </View>


                    {renderComponentContent()}
                    
                </View>
                
                

            </View>
            <View style={panelChild}>
                <CountryMetrics
                theme={theme}
                dataProp={currentData}
                currentMetrics={currentMetrics}
                />

            </View>
            <CountryModal
                theme={theme}
                open={modalVisible}
                setOpen={setModalVisible}
                currentMetrics={currentMetrics}
                modalArray={modalArray}
                configKeys={configKeys}
                setCurrentConfig={setCurrentConfig}
                />

        </View>

    )

}

export default CountrySummaryPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'   
    },
    
})