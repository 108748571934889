export default function CountrySummaryAgg (data, dim, currentMetric, countryLabels) {

    var dataset = data

    if (!dataset || dataset.length === undefined) {
      return null;
    }

    var agg_result = [];

    dataset.reduce(function (res, value) {
        const dimension = value[dim];
        const country = value.country;
        const brand = value.brand;
        const impressions = value.impressions;
        const clicks = value.clicks;
        const spend = value.spend;
        const regs = value.regs;
        const ftds = value.ftds;
        const ngr = value.ngr;
      
        // Create a unique key combining dimension and country
        const key = `${dimension}-${country}`;
      
        // Initialize the aggregation object if this key is not in res
        if (!res[key]) {
          res[key] = {
            [dim]: dimension,  // Store the dimension
            country: country,  // Store the country
            impressions: 0,
            clicks: 0,
            spend: 0,
            regs: 0,
            ftds: 0,
            ngr: 0
          };
          agg_result.push(res[key]);  // Add this entry to the aggregation result
        }
      
        // Aggregate values by summing them
        res[key].impressions += parseInt(impressions) || 0;
        res[key].clicks += parseInt(clicks) || 0;
        res[key].spend += parseFloat(spend) || 0;  // Spend should use parseFloat
        res[key].regs += parseInt(regs) || 0;
        res[key].ftds += parseInt(ftds) || 0;
        res[key].ngr += parseFloat(ngr) || 0;  // NGR should use parseFloat
        
        return res;
      }, {});

      let aggOutput = []

      countryLabels.forEach(country => {

        let filteredData = agg_result.filter(entry => entry.country === country);
        filteredData = filteredData.map((obj) => {
            const impressions = obj.impressions
            const clicks = obj.clicks
            const spend = obj.spend;
            const regs = obj.regs
            const ftds = obj.ftds;
            const ngr = obj.ngr;
            const ctr = impressions === 0 || clicks === 0 || isNaN(clicks) ? 0 : clicks / impressions;
            const cpc = spend === 0 || clicks === 0 || isNaN(clicks) ? 0 : spend / clicks;
            const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
            const cpr = spend === 0 || regs === 0 || isNaN(regs) ? 0 : spend / regs;
            const roas = spend === 0 || ngr === 0 || isNaN(ngr) ? 0 : ngr / spend;
            return {
              ...obj,
              ctr: parseFloat(ctr).toFixed(4),
              cpc: parseFloat(cpc).toFixed(2),
              cpa: parseInt(cpa),
              cpr: parseInt(cpr),
              roas: parseFloat(roas).toFixed(4),
            };
          });
        aggOutput.push(filteredData)

      })

      

      return aggOutput

}