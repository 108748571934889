export default function AnalyticsEntryCube(cubeFilters, table, start, end, reportDim) {

    return {

 
        "timeDimensions": [
          {
            "dimension": `${table}.timestamp`,
            "dateRange": [
              start,
              end
          ]
          }
        ],

        "order": [
            [
                `${table}.ftds`,
                "desc"
            ]
        ],
        "measures": [
            `${table}.pageviews`,
            `${table}.new_users`,
            `${table}.sessions`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.ftd_ngr`,
            `${table}.retention_ngr`,
            `${table}.retention_ggr`,
            `${table}.deposits`,
            `${table}.deposit_amount`,
            `${table}.aff_crossover`,
        ],
        "dimensions": [
            `${table}.${reportDim}`,
        ],
        "limit": 500,
        "filters": cubeFilters
    }

}