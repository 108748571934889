export default function StatFunctionMACD (data) {



function calculateEMA(data, key, windowSize) {
    const smoothingFactor = 2 / (windowSize + 1);
    let emaResults = [];

    // Initialize previous EMA
    let previousEMA = data.slice(0, windowSize).reduce((acc, obj) => acc + obj[key], 0) / windowSize;
    emaResults.push(previousEMA);

    // Calculate EMA for the rest of the data
    for (let i = windowSize; i < data.length; i++) {
        const currentValue = data[i][key];
        const currentEMA = (currentValue - previousEMA) * smoothingFactor + previousEMA;
        emaResults.push(currentEMA);
        previousEMA = currentEMA;
    }

    // Prepend `null` values for the first (windowSize - 1) entries where no EMA can be calculated
    return Array(windowSize - 1).fill(null).concat(emaResults);
}

// MACD Calculation
function calculateMACD(data, key, fastWindowSize = 12, slowWindowSize = 26, signalWindowSize = 9) {
    // Calculate fast EMA (e.g., 12 periods)
    const fastEMA = calculateEMA(data, key, fastWindowSize);

    // Calculate slow EMA (e.g., 26 periods)
    const slowEMA = calculateEMA(data, key, slowWindowSize);

    // Calculate MACD line (fast EMA - slow EMA)
    const macdLine = data.map((entry, index) => {
        if (fastEMA[index] === null || slowEMA[index] === null) {
            return null; // Can't calculate MACD without both fast and slow EMAs
        }
        return Math.round(fastEMA[index] - slowEMA[index]); // Round MACD line value
    });

    // Calculate Signal line (EMA of the MACD line)
    const signalLine = calculateEMA(macdLine.map((val, idx) => ({ [key]: val ?? 0 })), key, signalWindowSize)
        .map(value => (value !== null ? Math.round(value) : null)); // Round each signal line value

    // Calculate MACD Histogram (MACD line - Signal line)
    const macdHistogram = macdLine.map((value, index) => {
        if (signalLine[index] === null || value === null) {
            return null; // Can't calculate histogram without MACD line and signal line
        }
        return Math.round(value - signalLine[index]); // Round histogram value
    });

    // Return the full result: date, MACD line, Signal line, MACD Histogram
    return data.map((entry, index) => ({
        date: entry.date,
        macdLine: macdLine[index],
        signalLine: signalLine[index],
        macdHistogram: macdHistogram[index]
    }));
}

const macdCPA = calculateMACD(data, 'cpa');
const macdFTD = calculateMACD(data, 'ftds');
const macdCPR = calculateMACD(data, 'cpr');
const macdSpend = calculateMACD(data, 'spend');
const macdRegs = calculateMACD(data, 'regs');
const macdROAS = calculateMACD(data, 'roas');

const macdArray = {
    macdCPA,
    macdFTD,
    macdCPR,
    macdSpend,
    macdRegs,
    macdROAS
}

return macdArray

}