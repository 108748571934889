export default function CubeBudget(cubeFilters, table, start, end) {

    return {
        "timeDimensions": [
            {
                "dimension": `${table}.timestamp`,
                "granularity" : 'day',
                "dateRange": [
                    start,
                    end
                ]
            }
        ],
        "order": [
            [
                `${table}.timestamp`,
                "asc"
            ]
        ],
        "measures": [
            `${table}.actual_spend`,
            `${table}.yesterday_spend`,
            `${table}.budget`,
            `${table}.reg`,
            `${table}.ftd`,
            `${table}.ngr`,
        ],
        "dimensions": [
            `${table}.brand`,
            `${table}.channel`,
            `${table}.kw_type`,
            `${table}.calendar_date`,
            `${table}.current_dom`,
            `${table}.days_in_month`,
            `${table}.days_remaining`
        ],
        "limit": 2000,
        "filters": cubeFilters,
         "timezone": "UTC"
    }

}