import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable
} from 'react-native'
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment'

const windowWidth = Dimensions.get('window').width;


const SupportForm = (props) => {
    const theme = props.theme
    const saveHandler = props.saveHandler
    const configArray = props.configArray
    const client = configArray.clientID
    const userName = configArray.userName
    const userNickname = configArray.userNickname
    const submitTypes = ['General', 'Missing Data', 'Bug', 'Feature Request', 'Formatting Issues']
    const [typeValue, setTypeValue] = useState('General')

    const reportTypes = ['General', 'Summaries', 'Cohorts', 'Budget', 'PPC', 'Social', 'Display', 'GA4', 'Attribution', 'Pivot Tables', 'Statistics']
    const [reportValue, setReportValue] = useState('General')

    const criticalTypes = ['Yes', 'No']
    const [criticalValue, setCriticalValue] = useState('No')

    const [descriptionValue, setDescriptionValue] = useState('')
    const currentDate = moment.tz('Europe/Malta').format('YYYY-MM-DD')

    let criticalBool;

    if (criticalValue === 'Yes') {
        criticalBool = true
    } else {
        criticalBool = false
    }

    const supportData = {
        date: currentDate,
        client,
        userName,
        userNickname,
        typeValue,
        reportValue,
        criticalBool: criticalValue,
        descriptionValue,
        status : 'New'
    }

    const typeMap = submitTypes.map((item, index) => (
        <MenuItem key={index} value={item} sx={{ fontFamily: 'Electrolize' }}>
            {item}
        </MenuItem>
    ));

    const reportMap = reportTypes.map((item, index) => (
        <MenuItem key={index} value={item} sx={{ fontFamily: 'Electrolize' }}>
            {item}
        </MenuItem>
    ));

    const criticalMap = criticalTypes.map((item, index) => (
        <MenuItem key={index} value={item} sx={{ fontFamily: 'Electrolize' }}>
            {item}
        </MenuItem>
    ));


    return (
        <View style={styles.container}>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <FormControl sx={{ m: 1, minWidth: '50%', width: '50%', fontFamily: 'Electrolize' }}>

                    <TextField
                        id="outlined-multiline-flexible"
                        multiline
                        maxRows={4}
                        disabled
                        value={userNickname}
                        label={'User Name'}
                    />
                </FormControl>
            </Box>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <FormControl sx={{ m: 1, minWidth: '50%', width: '50%', fontFamily: 'Electrolize', }}>
                    <InputLabel id="demo-dialog-select-label" sx={{ fontFamily: 'Electrolize' }}>Ticket Type</InputLabel>
                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={typeValue}
                        sx={{ fontFamily: 'Electrolize' }}
                        onChange={(event) => setTypeValue(event.target.value)}
                        input={<OutlinedInput label="Ticket Type" />}
                    >
                        {typeMap}
                    </Select>
                </FormControl>
            </Box>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <FormControl sx={{ m: 1, minWidth: '50%', width: '50%', fontFamily: 'Electrolize', }}>
                    <InputLabel id="demo-dialog-select-label" sx={{ fontFamily: 'Electrolize' }}>Report Type</InputLabel>
                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={reportValue}
                        sx={{ fontFamily: 'Electrolize' }}
                        onChange={(event) => setReportValue(event.target.value)}
                        input={<OutlinedInput label="Report Type" />}
                    >
                        {reportMap}
                    </Select>
                </FormControl>
            </Box>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <FormControl sx={{ m: 1, minWidth: '50%', width: '50%', fontFamily: 'Electrolize', }}>
                    <InputLabel id="demo-dialog-select-label" sx={{ fontFamily: 'Electrolize' }}>Critical Issue e.g. crash, blank pages</InputLabel>
                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={criticalValue}
                        sx={{ fontFamily: 'Electrolize' }}
                        onChange={(event) => setCriticalValue(event.target.value)}
                        input={<OutlinedInput label="Critical Issue e.g. crash, blank pages" />}
                    >
                        {criticalMap}
                    </Select>
                </FormControl>
            </Box>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <FormControl sx={{ m: 1, minWidth: '50%', width: '50%', fontFamily: 'Electrolize', }}>

                    <TextField
                        id="outlined-multiline-static"
                        label="Description: please provide as much detail as possible"
                        multiline
                        rows={6}
                        onChange={(event) => setDescriptionValue(event.target.value)}
                    />
                </FormControl>
            </Box>
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}>
                <FormControl sx={{ m: 5, minWidth: '20%', width: '20%', fontFamily: 'Electrolize', }}>
                    <Pressable onPress={() => saveHandler(supportData)}>
                    <Fab variant="extended" color="primary"
                        style={{
                            fontFamily: 'Electrolize',
                            fontSize: windowWidth * 0.013,
                            backgroundColor: theme.backgroundColor2,
                            fontWeight: 600
                        }}>
                        <SendIcon sx={{ mr: 1 }} />
                        Submit
                    </Fab>
                    </Pressable>
                </FormControl>
            </Box>

        </View>
    )


}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'yellow',
        paddingTop: 0,
        flexDirection: 'column',
        //alignItems: 'center',
        width: '100%',
        height: '100%',
        //flex: 1,
    }

})

export default SupportForm