export default function SupportTicketsParse (supportData) {

    if (!supportData) {
        return null
    }   

    function filterByStatus(data, status) {
        return data.filter(item => item.current_case_status === status);
      }

    const newCases = filterByStatus(supportData, 'New');
    const openCases = filterByStatus(supportData, 'Open');
    const awaitingCases = filterByStatus(supportData, 'Awaiting Client Response');
    const resolvedCases = filterByStatus(supportData, 'Resolved');
    const backlogCases = filterByStatus(supportData, 'Backlog');
    const stagingCases = filterByStatus(supportData, 'Staging');

    const totalCasesCount = supportData.length || 0;
    const newCasesCount = newCases.length || 0;
    const openCasesCount = openCases.length || 0;
    const awaitingCasesCount = awaitingCases.length || 0;
    const resolvedCasesCount = resolvedCases.length || 0;
    const backlogCasesCount = backlogCases.length || 0;
    const stagingCasesCount = stagingCases.length || 0;

    return {
        newCases,
        openCases,
        awaitingCases,
        resolvedCases,
        backlogCases,
        totalCasesCount,
        newCasesCount,
        openCasesCount,
        awaitingCasesCount,
        resolvedCasesCount,
        backlogCasesCount,
        stagingCasesCount
    }

}