export default function AnalyticsEventsCube(cubeFilters, table, start, end, reportDim) {

    return {
        "timeDimensions": [
          {
            "dimension": `${table}.timestamp`,
            "dateRange": [
              start,
              end
          ]
          }
        ],
        "order": [
            [
                `${table}.event_count`,
                "desc"
            ]
        ],
        "measures": [
            `${table}.users`,
            `${table}.event_count`,
            `${table}.users_logged_in`,
        ],
        "dimensions": [
            `${table}.dim`,
        ],
        "limit": 100,
        "filters": cubeFilters
    }

}