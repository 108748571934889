export default function ExecSummaryChannelCube (table, filters) {

    return {
        "order": [
            [
                `${table}.dim`,
                "desc"
            ]
        ],
        "limit": 2000,
        "measures": [
        `${table}.minus2_day`,
        `${table}.minus3_day`,
        `${table}.mtd`,
        `${table}.prevMonth`,
        `${table}.yesterday`,
        ],
        "dimensions": [
        `${table}.dim`,
        `${table}.type`,
        `${table}.channel`,
        `${table}.brand`,
        `${table}.country`,
        `${table}.updated_at`,
        ],
        "filters" : filters
      }


}