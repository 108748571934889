export default function CountryFunction (data, client) {

    if (!data || data.length === undefined) {
        return null;
      }

    return data.map(item => {

        let country;
        
        if (client === 'test') {

            country = item.brand

            return {
                date: item.date,
                brand: item.brand,
                campaign: item.campaign,
                country: country,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.cpc,
                cpa: item.cpa,
                roas: item.roas
            }

        } else if (client === 'gamdom') {

            country = `Gamdom - ${item.campaign}`

            return {
                date: item.date,
                brand: item.brand,
                campaign: item.campaign,
                country: country,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.cpc,
                cpa: item.cpa,
                roas: item.roas
            }
            
        } else if (client === 'glitnor') {

            const glitnorCampaign = item.brand
            
            if (!glitnorCampaign) {
                country = 'Glitnor - Others'
            } else if (glitnorCampaign.includes('SWE')) {
                country = 'Glitnor - SWE'
            } else if (glitnorCampaign.includes('FIN')) {
                country = 'Glitnor - FIN'
            } else if (glitnorCampaign.includes('CHL')) {
                country = 'Glitnor - CHL'
            } else {
                country = 'Glitnor - Others'
            }

            return {
                date: item.date,
                brand: item.brand,
                campaign: item.campaign,
                country: country,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.cpc,
                cpa: item.cpa,
                roas: item.roas
            }
            
        } else if (client === 'hero') {

            const heroCampaign = item.campaign
            if (heroCampaign.includes('-DE-')) {
                country = "Hero - DE"
            } else if (heroCampaign.includes('-FI-')) {
                country = "Hero - FI"
            } else if (heroCampaign.includes('-SE-')) {
                country = "Hero - SE"
            } else if (heroCampaign.includes('-NL-')) {
                country = "Hero - NL"
            } else if (heroCampaign.includes('-JP-')) {
                country = "Hero - JP"
            } else if (heroCampaign.includes('-AU-')) {
                country = "Hero - AU"
            } else {
                country = 'Hero - Others'
            }

            return {
                date: item.date,
                brand: item.brand,
                campaign: item.campaign,
                country: country,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.cpc,
                cpa: item.cpa,
                roas: item.roas
            }
            
        } else if (client === 'ps') {

            country = `${item.brand}`

            return {
                date: item.date,
                brand: item.brand,
                campaign: item.campaign,
                country: country,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.cpc,
                cpa: item.cpa,
                roas: item.roas
            }
            
        } else if (client === 'rank') {

            country = `Rank - ES - ${item.brand}`

            return {
                date: item.date,
                brand: item.brand,
                campaign: item.campaign,
                country: country,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.cpc,
                cpa: item.cpa,
                roas: item.roas
            }
            
        }
    })

}