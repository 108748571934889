import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable,
    Text
} from 'react-native'
import LineDouble from '../../ChartsDesktop/LineDouble';
import TuneIcon from '@mui/icons-material/Tune';
import FiltersEnhancedSingle from '../../ComponentsEnhanced/FiltersEnhancedSingle';
import MixedChart from '../../ChartsDesktop/MixedChart';
import WebFont from 'webfontloader';
import StatFunctionsMovAv from './StatisticsFunctions/StatFunctionsMovAv';
import StatFunctionsEMA from './StatisticsFunctions/StatFunctionsEMA';
import StatFunctionMACD from './StatisticsFunctions/StatFunctionMACD';
import StatisticsModal from '../StatisticsModal';
import NoDataContainer from '../../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;

const MovingAveragePanels = (props) => {

    const client = props.client
    const dataProp = props.dataProp
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const currentMetrics = props.currentMetrics
    const filterParams = props.filterParams
    const dateLabels = props.dateLabels
    const currentData = props.agg_daily
    const timeFrame = props.timeFrame
    const statModel = props.statModel
    const reportList = props.reportList
    const [selectedComponent, setSelectedComponent] = useState("Row1_Line1");
    const [modalOpen, setModalOpen] = useState(false)
    const [currentStatModel, setCurrentStatModel] = useState(7)
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const barBrands = props.barBrands
    const filterList = props.filterList
    const setFilterList = props.setFilterList

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const calcMetrics = (data) => {

        return data.map(item => {

            return {
                date: item.date,
                impressions: item.impressions,
                clicks: item.clicks,
                spend: item.spend,
                regs: item.regs,
                ftds: item.ftds,
                ngr: item.ngr,
                cpc: item.clicks > 0 ? item.spend / item.clicks : 0,
                cpc: item.impressions > 0 ? item.clicks / item.impressions : 0,
                cpa: item.ftds > 0 ? item.spend / item.ftds : 0,
                cpr: item.regs > 0 ? item.spend / item.regs : 0,
                roas: item.spend > 0 ? item.ngr / item.spend : 0,
            }
        })
    }

    const aggResult = calcMetrics(currentData)

    if (aggResult.length <= 26) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    let dynamicData;
    

    if (statModel === 'moving_average') {
        dynamicData = StatFunctionsMovAv(aggResult, currentStatModel)
    } else if (statModel === 'expo_moving_average') {
        dynamicData = StatFunctionsEMA(aggResult, currentStatModel)
    }else {
        dynamicData = currentData
    }

    const handleComponentClick = (componentName) => {
        setSelectedComponent(componentName);
    };

    

    const renderComponentContent = () => {
        if (selectedComponent === 'Row1_Line1') {
            return <Row1Line1 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line2') {
            return <Row1Line2 style={panelMain} />;
        } else if (selectedComponent === 'Row1_Line3') {
            return <Row1Line3 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar1') {
            return <Row2Bar1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Mixed1') {
            return <Row2Mixed1 style={panelMain} />;
        } else if (selectedComponent === 'Row2_Bar2') {
            return <Row2Bar2 style={panelMain} />;
        } else {
            return null;
        }
    };

    function setModel (value) {
        setCurrentStatModel(value)
    }


    const panelMain = {

        width: '100%',
        height: windowHeight * 0.65,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',     

        
    }

    const panelChild = {
        flex: 1,
        height: '100%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 20,
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))',
    }

    const lineChart = {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5))',
    }

    let topValue, rightValue;

    if (windowWidth > 1800) {
        topValue = "10%"
        rightValue = "1%"
    } else {
        topValue = "15.5%"
        rightValue = "1%"
    }


    const settingsContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize',
    }

    const settingStyle = {
        fontFamily: 'Electrolize',
        color: 'red',
        fontSize: windowWidth * 0.015
    }


    const Row1Line1 = () => {

        return (
            <View style={lineChart}>
                <LineDouble
                        dataProp={dynamicData}
                        dataPropOriginal={aggResult}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'cpa'}
                        metric2={'cpa'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
            </View>)
    }

    const Row1Line2 = () => {

        return (
            <View style={lineChart}>
                <LineDouble
                        dataProp={dynamicData}
                        dataPropOriginal={aggResult}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'cpr'}
                        metric2={'cpr'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
            </View>)
    }


    const Row1Line3 = () => {

        return (
            <View style={lineChart}>
                <LineDouble
                        dataProp={dynamicData}
                        dataPropOriginal={aggResult}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'roas'}
                        metric2={'roas'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
            </View>)
    }

    const Row2Bar1 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={barBrands}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'spend'}
                    metric2={'ftds'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                    statModel={statModel}
                />
            </View>)
    }

    const Row2Mixed1 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={barBrands}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'regs'}
                    metric2={'ftds'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                    statModel={statModel}
                />
            </View>)
    }

    const Row2Bar2 = () => {
        return (
            <View style={lineChart}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={barBrands}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'spend'}
                    metric2={'cpa'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                    statModel={statModel}
                />
            </View>)
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                            large={1}
                            
                        />
                    </View>
                    <Pressable 
                        style={settingsContainer}
                        onPress={() => setModalOpen(true)}
                        >
                        <TuneIcon
                            style={settingStyle}
                        />
                    </Pressable>


                    {renderComponentContent()}
                </View>
            </View>
            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line1')}>
                    <View style={styles.lineChart}>
                    <LineDouble
                        dataProp={dynamicData}
                        dataPropOriginal={aggResult}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'cpa'}
                        metric2={'cpa'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line2')}>
                    <View style={styles.lineChart}>
                    <LineDouble
                        dataProp={dynamicData}
                        dataPropOriginal={aggResult}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'cpr'}
                        metric2={'cpr'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
                    </View>
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row1_Line3')}>
                    <View style={styles.lineChart}>
                    <LineDouble
                        dataProp={dynamicData}
                        dataPropOriginal={aggResult}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'roas'}
                        metric2={'roas'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
                    </View>
                </Pressable>

            </View>

            <View style={{ flexDirection: 'row', flex: 1 }}>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar1')}>
                <MixedChart
                    dataProp={dynamicData}
                    labels={barBrands}
                    theme={theme}
                    agg_daily_result={dynamicData}
                    dateLabels={dateLabels}
                    metric1={'spend'}
                    metric2={'ftds'}
                    timeFrame={timeFrame}
                    client={client}
                    currentMetrics={currentMetrics}
                    statModel={statModel}
                />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Mixed1')}>
                    <MixedChart
                        dataProp={dynamicData}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'regs'}
                        metric2={'ftds'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
                </Pressable>
                <Pressable style={panelChild} onPress={() => handleComponentClick('Row2_Bar2')}>
                    <MixedChart
                        dataProp={dynamicData}
                        labels={barBrands}
                        theme={theme}
                        agg_daily_result={dynamicData}
                        dateLabels={dateLabels}
                        metric1={'spend'}
                        metric2={'cpa'}
                        timeFrame={timeFrame}
                        client={client}
                        currentMetrics={currentMetrics}
                        statModel={statModel}
                    />
                </Pressable>
                

            </View>
            <StatisticsModal
                    theme={theme}
                    open={modalOpen}
                    setOpen={setModalOpen}
                    setMetric={setModel}
                    currentMetrics={currentMetrics}
                    reportList={reportList}
                    statModel={statModel}
                />

        </View>

    )

}

export default MovingAveragePanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: (windowHeight * 0.93) * 1.3,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'   
    },
    
})