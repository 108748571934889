import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport
} from '@mui/x-data-grid';
import { Dimensions } from 'react-native'
import WebFont from 'webfontloader';
import { LicenseInfo } from '@mui/x-license-pro';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
} from '@mui/x-data-grid-premium';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

export default function ExecSummaryMetrics(props) {

  LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
  const dataProp = props.dataProp
  const theme = props.theme
  const isAllPercent = props.isAllPercent
  const itemId = props.itemId

  const [aggregationModel, setAggregationModel] = useState({
    spend: 'sum',
    ftds: 'sum',
    cpa: 'cpa',
    clicks: 'sum',
    impressions: 'sum',
    regs: 'sum',
    adgroup_name: 'total',
    keyword: 'total',
    total_conv: 'sum',
    deposits: 'sum'
  });



  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.018
  } else { responseText = windowHeight * 0.017 }

  let responseRow;

  if (windowWidth < 1281) {
    responseRow = windowHeight * 0.05
  } else if (windowWidth > 2000) {
    responseRow = windowHeight * 0.05
  } else { responseRow = windowHeight * 0.04 }


  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    clicks: false,
    impressions: false,
    regs: false,
    channel: true,
    total_conv: true,
    arpu: false,
    deposits: false
  });

  const valueFormat = (value, field, row) => {

    if (field === 'metric' || field === 'dim') {
      return value
    } else if (isAllPercent) {
      const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
      return `${percent}%`;
    } else if (field === 'mom' || field === 'mtd_yoy' || field === 'ytd_yoy' || row.metric === 'GGR Margin' || row.metric === 'Hold') {
      const percent = (value * 100).toFixed(0); // Convert to percent and round to 2 decimal places
      return `${percent}%`;
    } else if (itemId === 'ngrProductData' || itemId === 'ggrProductData' || itemId === 'betProductData') {
      return `€${value.toLocaleString('en-UK', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    } else if (itemId === 'ngrVipData' || itemId === 'arpuVipData' || itemId === 'depositsVipData' || itemId === 'bonusVipData') {
      return `€${value.toLocaleString('en-UK', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    } else if (itemId === 'ggrProviderData' || itemId === 'betProviderData') {
      return `€${value.toLocaleString('en-UK', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    } else if (row.metric === 'NGR' || row.metric === 'GGR' || row.metric === 'Deposits Completed' || row.metric === 'Cashwager' || row.metric === 'ADPU' || row.metric === 'ARPU' || row.metric === 'Withdrawal Amount' || row.metric === 'Bonus' || row.dim === 'CPA - Brand' || row.dim === 'CPA - Non-Brand' || row.dim === 'Spend' || row.dim === 'CPC' || row.dim === 'CPM' || row.dim === 'NGR') {     
      return `€${value.toLocaleString('en-UK', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;      
      //return `€${value}`;
    } else if (row.metric === 'Deposit Count' || row.metric === 'Actives' || row.metric === 'Depositors' || row.metric === 'Registrations' || row.metric === 'FTD') {     
      return `${value.toLocaleString('en-UK', { style: 'decimal', minimumFractionDigits: 0, maximumFractionDigits: 0 })}`;
    }
    // format by PRODUCT / VIP
    return value;
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Electrolize']
      }
    });
  }, []);

  let uniqueIdCounter = 1;

  // Use forEach to add a unique ID to each record
  dataProp.forEach((record) => {
    record.id = uniqueIdCounter++;
  });

  const labelObject = dataProp[0] || []

  function simpleHeatmap(value) {

    if (value < 0) {
      return 'rgba(255,5,63,0.9)';
    } else if (value > 0) {
      return 'rgba(64,255,0,0.9)';
    } else {
      return 'rgba(255, 255, 0, 0.9)'
    }

  }

  function simpleFontmap(value, field) {

    if (value < 0 && field.field === 'mom') {
      return 'white'
    } else if (value >= 0 && field.field === 'mom') {
      return 'grey'
    } else if (value < 0 && field.field === 'mtd_yoy') {
      return 'white'
    } else if (value >= 0 && field.field === 'mtd_yoy') {
      return 'grey'
    } else if (value < 0 && field.field === 'ytd_yoy') {
      return 'white'
    } else if (value >= 0 && field.field === 'ytd_yoy') {
      return 'grey'
    } else if (value > 0) {
      return theme.color;
    } else {
      return theme.color
    }

  }

  function execHeatmap(value, field, row) {
    let heatMap;

    if (field.field === 'mom' || field.field === 'mtd_yoy' || field.field === 'ytd_yoy') {
      heatMap = simpleHeatmap(value)
    } else {
      heatMap = theme.backgroundColor
    }
    return heatMap

  }

  const headerCustom = (key) => {
    if (key === 'dim') {
      return 'metric'
    } else if (key === 'minus2_day') {
      return 'two_days_ago'
    }  else if (key === 'minus3_day') {
      return 'three_days_ago'
    } else if (key === 'prevMonth') {
      return 'previous_mtd'
    } else {
      return key
    }
  }

  const labels = Object.keys(labelObject).map((key, index) => {
    return {
      field: key,
      headerName: headerCustom(key),
      //minWidth: windowWidth * columnWidth * 1.25, 
      flex: 1,
      headerAlign: 'center',
      headerClassName: 'table-header',
      align: 'center',
      type: 'number',
      renderCell: (params) => {
        const { value, field, row } = params;
        const heatmapColor = execHeatmap(value, { field }, row);
        const fontColour = simpleFontmap(value, { field })

        if (index < 1) {
          return (
            <div
              className="heatmap-cell"
              style={{
                backgroundColor: heatmapColor,
                padding: '7%',
                color: theme.color,
                width: '100%',
                fontSize: responseText * 0.8,
              }}
            >
              {valueFormat(params.value, params.field, params.row)}
            </div>
          );
        } else if (params.aggregation) {
          return (
            <div
              className="heatmap-cell-aggregated"
              style={{
                fontWeight: 'bold', // Add your custom font weight
                display: 'flex', // Apply flex styles to center content vertically/horizontally
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%', // Ensure the cell takes up the full height
                width: '100%',
                color: fontColour,
                paddingLeft: 0,
                fontFamily: 'Electrolize',
                fontSize: responseText * 0.8,
              }}
            >
              {valueFormat(params.value, params.field, params.row)}
            </div>
          );
        } else return (
          <div
            className="heatmap-cell"
            style={{
              backgroundColor: heatmapColor,
              padding: '10%',
              width: '100%',
              fontSize: responseText * 0.8,
              color: fontColour

            }}
          >
            {valueFormat(params.value, params.field, params.row)}
          </div>
        );
      },

    };
  });

  const columnRanges = {};
  Object.keys(labelObject).forEach((column) => {
    const values = dataProp.map((record) => record[column]);
    const min = Math.min(...values);
    const max = Math.max(...values);
    columnRanges[column] = { min, max };
  });

  function calculateHeatmapColor(value, column, row) {
    const { min, max } = columnRanges[column.field];
    const range = (max + 1);

    // Define input colors for the heatmap in RGB format
    //const fromColor = { r: theme.comboStopColor1ARRAY[0], g: theme.comboStopColor1ARRAY[1], b: theme.comboStopColor1ARRAY[2] };
    //const toColor = { r: theme.comboStopColor2ARRAY[0], g: theme.comboStopColor2ARRAY[1], b: theme.comboStopColor2ARRAY[2] };

    const fromColor = { r: 255, g: 5, b: 63 };
    const toColor = { r: 64, g: 255, b: 0 };

    const relativeValue = (value - min) / range;
    const red = Math.round((1 - relativeValue) * fromColor.r + relativeValue * toColor.r);
    const green = Math.round((1 - relativeValue) * fromColor.g + relativeValue * toColor.g);
    const blue = Math.round((1 - relativeValue) * fromColor.b + relativeValue * toColor.b);

    return `rgba(${red},${green},${blue},0.9)`;
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ color: theme.color }}>
        <GridToolbarColumnsButton sx={{ color: theme.color }} />
        <GridToolbarFilterButton sx={{ color: theme.color }} />
        <GridToolbarDensitySelector sx={{ color: theme.color }} />
        <GridToolbarExport sx={{ color: theme.color }} />
      </GridToolbarContainer>
    );
  }

  function calculateCPA(spend, ftd) {
    const cpa = (spend / ftd)

    return Math.round(cpa)
  }

  function calculateARPU(ngr, ftd) {
    const arpu = (ngr / ftd)

    return Math.round(arpu)
  }

  function calculateTotal(spend, ftd) {
    return "TOTAL"
  }

  const cpa = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.total_conv }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
        }
      });
      return calculateCPA(spend, ftd);
    },
    columnTypes: ['number'],
  };


  const total = {
    label: '',
    getCellValue: ({ row }) => ({ spend: row.spend, ftd: row.ftds }),
    apply: ({ values }) => {
      let spend = 0;
      let ftd = 0;
      values.forEach((value) => {
        if (value) {
          spend += value.spend || 0; // Adds 0 if value.spend is null or NaN
          ftd += value.ftd || 0;
        }
      });
      return calculateTotal(spend, ftd);
    },
    columnTypes: ['number'],
  };

  const largeRows = ((windowHeight / 55).toFixed(0))

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        fontSize: 50,
        color: 'black',
        fontFamily: 'Electrolize',


      }}
      sx={{
        '& .table-header': {
          headerAlign: 'center',
          align: 'center',
          fontFamily: 'Electrolize',
          color: theme.color,
          fontSize: responseText,
        },
        '& .MuiDataGrid-row': { backgroundColor: theme.backgroundColor },
        '& .MuiTablePagination-root': { color: theme.color },
        width: '100%'
      }}
    >
      <DataGridPremium
        rows={dataProp}
        columns={labels}
        rowHeight={responseRow}
        slots={{ toolbar: CustomToolbar }}
        pagination
        rowsPerPageOptions={[]}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        aggregationModel={aggregationModel}
        onAggregationModelChange={(newModel) => setAggregationModel(newModel)}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          cpa,
          total,
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 20 }
          },
          sorting: {
            sortModel: [{ field: 'metric', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[5, 10, 20]}

      />
    </Box>
  );
}