import moment from 'moment-timezone';

export default function AnalyticsEntryParse (cubeResponse, xAxisValue) {

    var dataset = cubeResponse

    if (!dataset || dataset.length === undefined) {
      return null;
    }

    var agg_result = [];

      dataset.reduce(function (res, value) {

        if (!res[value[xAxisValue]]) {
          res[value[xAxisValue]] = {
            [xAxisValue]: value[xAxisValue],
            new_users: 0,
            sessions: 0,
            pageviews: 0,
            //reg_start: 0,
            regs: 0,
            ftds: 0,
            ftd_ngr: 0,
            retention_ngr: 0,
            deposits: 0,
            deposit_amount: 0,
            aff_crossover: 0
          };
          agg_result.push(res[value[xAxisValue]]);
        }
        res[value[xAxisValue]].new_users += value.new_users || 0;
        res[value[xAxisValue]].sessions += value.sessions || 0;
        res[value[xAxisValue]].pageviews += value.pageviews || 0;
        //res[value[xAxisValue]].reg_start += value.reg_start;
        res[value[xAxisValue]].regs += value.regs || 0;
        res[value[xAxisValue]].ftds += parseInt(value.ftds) || 0;
        res[value[xAxisValue]].ftd_ngr += parseInt(value.ftd_ngr) || 0;
        res[value[xAxisValue]].retention_ngr += (parseFloat(value.retention_ngr) || 0);
        res[value[xAxisValue]].deposits += parseInt(value.deposits) || 0;
        res[value[xAxisValue]].deposit_amount += (parseFloat(value.deposit_amount) || 0);
        res[value[xAxisValue]].aff_crossover += parseInt(value.aff_crossover) || 0;
    
        return res;
      }, {});

      agg_result = agg_result.map((obj) => {
        const ftds = obj.ftds;
        const new_users = obj.new_users;
        const aff_crossover = obj.aff_crossover;
        const convRate = new_users === 0 || ftds === 0 || isNaN(ftds) ? 0 : ftds / new_users;
        const affCrossoverRate = aff_crossover === 0 || ftds === 0 || isNaN(ftds) ? 0 : aff_crossover / ftds;

        return {
          ...obj,
          convRate: parseFloat(convRate),
          affCrossoverRate: parseFloat(affCrossoverRate),
        };
      });

      return agg_result

}