export default function ParseDaily(cubeFilters, table, start, end) {

    return {
        "filters": cubeFilters,
        "limit": 5000,
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.ngr`,
        ],
        "timeDimensions": [
            {
              "dateRange": [
                start,
                end
              ],
              "dimension": `${table}.timestamp`,
              "granularity": "day"
            }
          ],
          "timezone": "Europe/Malta",
        "order": { [`${table}.timestamp`]: "asc" }
        
        
        
    }

}