import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import ParseDimensions from '../CubeFunctions/ParseDimensions.js';
import DashCubeQuery from '../DashBuilder/DashCube/DashCubeQuery.js';
import CubeDailyFunction from '../DailySummary/CubeFunctions/CubeDailyFunction.js';
import DashEditorContent from './DashEditorContent.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const DashEditorPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    // Convert the start and end dates to strings in YYYY-MM-DD format
    const startDateString = previousDate.toISOString().slice(0, 10);
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');

    const initialLayout = [
        { i: "item1", x: 0, y: 0, w: 5, h: 5, maxH: 22, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}  },
        { i: "item2", x: 1, y: 0, w: 6, h: 4, minW: 2, maxW: 4, maxH: 22, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}  },
        { i: "item3", x: 4,y: 0, w: 4, h: 4, maxH: 22, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp}  }
      ]

      const initialChartLayout = [
        { i: "item1", x: 0, y: 0, w: 5, h: 5, maxH: 22, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
        { i: "item2", x: 1, y: 0, w: 6, h: 4, minW: 2, maxW: 4, maxH: 22, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
        { i: "item3", x: 4, y: 0, w: 4, h: 4, maxH: 22, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp, reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} }
      ];

      const initialConfig = [
        {"gridConfig": "gridConfig1", "gridLayout":initialLayout,  "gridChartLayout": initialChartLayout, "reportName" : 'Dashboard 1', 'size' : 3}
    ]

    const initialDashConfig = JSON.parse(localStorage.getItem("gridConfig"))
    
    if (!initialDashConfig) {
    localStorage.setItem("gridConfig", JSON.stringify([initialConfig]))
    };

    
    const getDashConfigs = JSON.parse(localStorage.getItem("gridConfig")) 
    const [dashConfigs, setDashConfigs] = useState(getDashConfigs)
    const viewerVisible = props.viewerVisible
    const setViewerVisible = props.setViewerVisible
    const [editorVisible, setEditorVisible] = useState(false)
    const homeTitle = "Daily Summary";
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_daily";

    

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        channel: "Total",
        brand: 'Total',
        kw_type: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    const brand = (filters.brand === "Total") ? "null" : filters.brand;
    const channel = (filters.channel === "Total") ? "null" : filters.channel;
    const type = (filters.kw_type === "Total") ? "null" : filters.kw_type;

    const brandOp = (filters.brand === "Total") ? "notEquals" : "equals";
    const channelOp = (filters.channel === "Total") ? "notEquals" : "equals";
    const typeOp = (filters.kw_type === "Total") ? "notEquals" : "equals";

    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        brand: "Total",
        channel: "Total",
        kw_type: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            channel: prop.channel,
            brand: prop.brand,
            kw_type: prop.kw_type,
        };

        setFilters(filterUpdate)

    };




    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    let brandFilter;

    if(brand === "null") {
        brandFilter = [brand]
    } else {brandFilter = brand}

    let typeFilter;

    if(type === "null") {
        typeFilter = [type]
    } else {typeFilter = type}

    let channelFilter;

    if(channel === "null") {
        channelFilter = [channel]
    } else {channelFilter = channel}

    const cubeFilters = [
        {
            "member": `${cubeTable}.brand`,
            "operator": `${brandOp}`,
            "values": brandFilter
        },
        {
            "member": `${cubeTable}.channel`,
            "operator": `${channelOp}`,
            "values":  channelFilter
            
        },
        {
            "member": `${cubeTable}.kw_type`,
            "operator": `${typeOp}`,
            "values": typeFilter
        }
    ]


    const cubeQuery = DashCubeQuery(cubeFilters, cubeTable, start, end)
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = CubeDailyFunction (cubeTable, start, end, 'brand', 'channel', 'kw_type', cubeFilters)
    const dimResult = useCubeQuery(dimQuery)

    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }


    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data    

    const uniqueData = ParseDimensions(dimData, cubeTable)

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    const uniqueBrands = uniqueData.uniqueBrands;
    const uniqueChannels = uniqueData.uniqueChannels;
    const uniqueTypes = uniqueData.uniqueTypes;

    const updateArray = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = dimData?.[0]?.[updateVar] || 'loading...';

    if (updateArray.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    const agg_result = updateArray.map((obj) => {
        const date = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
        const spend = parseFloat(obj.spend)
        const ftds = parseInt(obj.ftds)
        const cpa = parseFloat((spend / ftds).toFixed(2))
        const impressions = parseInt(obj.impressions)
        const clicks = parseInt(obj.clicks)
        const regs = parseInt(obj.regs)
        const cpc = parseFloat((spend / clicks).toFixed(2))
        const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

        return {
            ...obj,
            cpc: cpc,
            cpa: cpa,
            clickConv: ftdConv

        };
    });

    const dateLabels = getUniqueValues(agg_result, 'date')

    function addDashboard () {
        const configLength = dashConfigs.length
        const newDashName = 'gridConfig'+(configLength + 1)
        const reportName = 'Dashboard '+(configLength + 1)
        const layoutName = 'gridLayout'
        const chartLayoutName = 'gridChartLayout'

        const initialLayout = [
            { i: "item1", x: 0, y: 0, w: 2, h: 2, maxH: 18, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item2", x: 1, y: 0, w: 2, h: 2, minW: 2, maxW: 4, maxH: 18, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item3", x: 4, y: 0, w: 2, h: 2, maxH: 18, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} }
          ]

          const initialChartLayout = [
            { i: "item1", x: 0, y: 0, w: 5, h: 5, maxH: 22, type: 'bar', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 1', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item2", x: 1, y: 0, w: 6, h: 4, minW: 2, maxW: 4, maxH: 22, type: 'line', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 2', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} },
            { i: "item3", x: 4, y: 0, w: 4, h: 4, maxH: 22, type: 'mixed', xAxis: 'date', time: 'daily', metric1: 'spend', metric2: 'cpa', start: startDateString, end: yesterdayProp,  reportName: 'Dashboard 3', currentGraphFilters : {brand: 'Total', channel: 'Total', kw_type: 'Total', start: startDateString, end: yesterdayProp} }
          ];

        const newChild = [
            {"gridConfig": newDashName, gridLayout :initialLayout,  gridChartLayout: initialChartLayout, reportName: reportName }
        ]
     
        
        const configCopy = [...dashConfigs, newChild];       
    
    // Update the state with the new array
        setDashConfigs(configCopy);

        localStorage.setItem("gridConfig", JSON.stringify(configCopy));
        
    }

    function removeDashboard(gridConfigValue) {
        // Check if the length of dashConfigs is 1
        if (dashConfigs.length === 1) {
            return; // Do nothing if there is only one item
        }
    
        // Filter out the item based on the gridConfigValue
        const configCopy = dashConfigs.filter((item, index) => index !== gridConfigValue);
        setDashConfigs(configCopy);
    
        localStorage.setItem("gridConfig", JSON.stringify(configCopy));        
    }

    return (
        <View style={styles.container}>

                <DashEditorContent

                    theme={theme}
                    uniqueBrands={uniqueBrands}
                    uniqueChannels={uniqueChannels}
                    uniqueTypes={uniqueTypes}
                    dateLabels={dateLabels}
                    agg_result={agg_result}
                    cubeTable={cubeTable}
                    dashConfigs={dashConfigs}
                    setDashConfigs={setDashConfigs}
                    viewerVisible={viewerVisible}
                    setViewerVisible={setViewerVisible}
                    editorVisible={editorVisible}
                    setEditorVisible={setEditorVisible}
                    addDashboard={addDashboard}
                    removeDashboard={removeDashboard}
                    filters={filters}
                    setFilters={setFilters}
                    startDateString={startDateString}
                    yesterdayProp={yesterdayProp}
                    currentMetrics={currentMetrics}
                />


        </View>

    )

}

export default DashEditorPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: windowHeight * 0.929,
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})