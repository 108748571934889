import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions
} from 'react-native'
import FiltersDate from './FiltersDate';
import FiltersDynamicMultiLarge from './FiltersDynamicMultiLarge';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const windowHeight = Dimensions.get('window').height;
const windowWidth = Dimensions.get('window').width;


const FiltersEnhancedPaths = (props) => {

    const uniqueFilter1 = props.uniqueFilter1
    const largeProp = props.large
    const type = props.type
    const propObject = props.propObject
    const handleStateChange = props.handleStateChange
    const brandFilterState = props.brandFilterState !== undefined ? props.brandFilterState : false;
    const theme = props.theme
    const filterParams = props.filterParams
    const [currentFilterValue, setCurrentValue] = useState('Total')
    const filter1name = filterParams.filter1name
    const setFilter1List = filterParams.setFilter1List
    const filterList = props.filterList
    const setFilterValueList = props.setFilterList
    const textInput = props.textInput
    const setTextInput = props.setTextInput
    const singleModel = props.singleModel
    const doubleFilter = props.doubleFilter
    const large = props.large

    let filterWidth;

    if (doubleFilter && singleModel && !large) {
        filterWidth = windowWidth * 0.20
    } else if (doubleFilter && singleModel && large) {
        filterWidth = windowWidth * 0.33
    } else if (!singleModel && !large) {
        filterWidth = windowWidth * 0.18
    } else if (!singleModel && large) {
        filterWidth = windowWidth * 0.18
    } else if (singleModel && large) {
        filterWidth = windowWidth * 0.24
    } else {
        filterWidth = windowWidth * 0.16
    }

    let responseMargin;

  if (windowHeight < 800 && large === 1) {
    responseMargin = '-1%'
  }  else if (windowWidth > 2000) {
    responseMargin = '-0.8%'
  } else if (windowHeight > 800 && large === 1) {
    responseMargin = '-1.2%'
  }  else if (windowHeight > 800) {
    responseMargin = '-1%'
  } else if (windowWidth < 1441 && large === 1) {
    responseMargin = '-3%'
  } else if (windowWidth < 1441) {
    responseMargin = '-1%'
  } else  {responseMargin = '-1%'}

  let responseText;

  if (windowWidth < 1281) {
    responseText = windowHeight * 0.025
  } else if (windowWidth > 2000 && large === 1) {
    responseText = windowHeight * 0.012
  } else if (windowWidth > 2000) {
    responseText = windowHeight * 0.01
  } else {responseText = windowHeight * 0.017}

    return (


        <View style={styles.container}>
            <View style={styles.filterContainer}>
                <FiltersDynamicMultiLarge
                    listValues={uniqueFilter1}
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    brandFilterState={brandFilterState}
                    large={large}
                    type={filter1name}
                    filterParams={filterParams}
                    setList={setFilter1List}
                    singleModel={true}
                    currentFilterValue={currentFilterValue}
                    setCurrentValue={setCurrentValue}
                    filterIndex={0}
                    setFilterList={setFilterValueList}
                    filterList={filterList}
                />
            </View>
            <View style={styles.filterContainer}>
            <FormControl sx={{ width: filterWidth,
                                fontFamily : 'Electrolize' , 
                                color : theme.color,
                                borderWidth : 1,
                                borderColor: theme.backgroundColor2,
                                //height: 40,
                                //maxHeight: 40,
                                alignItems: 'center',
                                //justifyContent: 'center',
                                //border: `3px solid ${theme.backgroundColor2}`,
                                 }} >
                <InputLabel id="demo-multiple-checkbox-label"
                    style={{
                        fontFamily: 'Electrolize',
                        color : theme.color,
                        borderWidth : 0,
                        borderColor: theme.backgroundColor2,
                        fontSize: responseText,
                        marginTop: responseMargin,  
                        textTransform: 'capitalize'
                    }}
                >Text Filter</InputLabel>
                <OutlinedInput 
                    id="outlined-search" 
                    onChange={e => setTextInput(e.target.value)}
                    type="search"
                    style={{
                        width : '100%' ,
                        left : 0, 
                        fontFamily : 'Electrolize', 
                        color : theme.color , 
                        backgroundColor : theme.backgroundColor,
                        border: `1px solid ${theme.backgroundColor2}`,
                        height: 45,
                        //maxHeight: 40
                    }}
                    
                    />
            </FormControl>
 

            </View>

            

            <View style={styles.dateFilterContainer}>
                <FiltersDate
                    propObject={propObject}
                    handleStateChange={handleStateChange}
                    theme={theme}
                    large={large}
                    type={type}
                />
            </View>
        </View>


    )

}

export default FiltersEnhancedPaths

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        marginTop: '0.9%',
        alignItems: 'center',
        justifyContent: 'center'

    },
    filterContainer: {
        width: '25%',
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 0,
        borderColor: 'blue',
        paddingLeft: '0.5%',
 
    },
    dateFilterContainer: {

        borderWidth: 0,
        borderColor: 'red',
        width: '25%',
        paddingLeft: '0.5%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: '0.1%'


    }
})