import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import ChartBuilderPanels from './ChartBuilderPanels.js';
import NoData from '../Components/NoData.js';
import ParseDaily from '../DailySummary/CubeFunctions/ParseDaily.js';
import ChartBuilderCampaignCube from './ChartBuilderCampaign/ChartBuilderCampaignCube.js';
import CubeBudget from '../Budgets/CubeFunctions/CubeBudget.js';
import NoDataContainer from '../Components/NoDataContainer.js';
import PivotDims from '../Flexmonster/CubeFunctions/PivotDims.js';

const ChartBuilderPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentReportConfig = props.currentReportConfig
    const setCurrentReportConfig = props.setCurrentReportConfig
    const flexConfigStorage = props.flexConfigStorage
    const lastFlexUsed = props.lastFlexUsed
    const newSettings = props.newSettings
    const setNewSettings = props.setNewSettings
    const reportList = props.reportList
    const reportMap = props.reportMap
    const [panelContent, setPanelContent] = useState('time')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
     const dynamicFilter = 'channel'

    const currentDate = moment.tz('Europe/Malta');
    const yesterday = currentDate.clone().subtract(1, 'day');
    const firstDayOfYesterdaysMonth = yesterday.clone().startOf('month');
    const firstDayOfYesterdaysMonthProp = firstDayOfYesterdaysMonth.format('YYYY-MM-DD');
    const startDateString = firstDayOfYesterdaysMonthProp


    

    let cubeTableDynamic;

    if (panelContent === 'time') {
        cubeTableDynamic = `${client}_daily`
    } else if (panelContent === 'budget') {
        cubeTableDynamic = `${client}_budget`
    } else if (panelContent === 'adgroup') {
        cubeTableDynamic = `${client}_adgroup`
    }

    let dynamicFilter3;
    

    if (panelContent === 'adgroup') {
        dynamicFilter3 ='kw_type'
    } else if (panelContent === 'budget') {
        dynamicFilter3 = 'kw_type'
    } else if (panelContent === 'time') {
        dynamicFilter3 = 'kw_type'
    }

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = dynamicFilter3
    const filter4name = 'country'
    console.log(dynamicFilter3)

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')
    const [filter4List, setFilter4List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : [],
        filter4List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);


    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter4name,
        filter1List,
        filter2List,
        filter3List,
        filter4List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
        setFilter4List
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }



    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [propObject, setpropObject] = useState(propObjectBase);

    const handleStateChange = (prop) => {

        setpropObject(prop);

        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
        };

        setFilters(filterUpdate)

    };


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTableDynamic}.${filter1name}`,
            "operator": `${filter1Operator}`,
            "values": filter1Filter
        },
        {
            "member": `${cubeTableDynamic}.${filter2name}`,
            "operator": `${filter2Operator}`,
            "values": filter2Filter
        },
        {
            "member": `${cubeTableDynamic}.${filter3name}`,
            "operator": `${filter3Operator}`,
            "values": filter3Filter
        }
    ]


    let cubeQueryDynamic;

    if (panelContent === 'adgroup') {
        cubeQueryDynamic = ChartBuilderCampaignCube(cubeFilters, cubeTableDynamic, start, end)
        
    } else if (panelContent === 'budget') {
        cubeQueryDynamic = CubeBudget(cubeFilters, cubeTableDynamic, startDateString, yesterdayProp)
    } else if(panelContent === 'time') {
        cubeQueryDynamic = ParseDaily(cubeFilters, cubeTableDynamic, start, end)
        
        
    }

    const { resultSet } = useCubeQuery(cubeQueryDynamic);
    const dimQuery = PivotDims (cubeTableDynamic, filter1name, filter2name, filter3name, cubeFilters)
    const dimResult = useCubeQuery(dimQuery)


    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }


    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };

    

    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter1name)
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter2name)
    // repeat filters 1 & 2
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTableDynamic, dynamicFilter3)
    const uniqueFilter4 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter2name)

    const aggResult = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTableDynamic + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTableDynamic}.updated_at`
    const lastUpdated = dimData?.[0]?.[updateVar] || 'loading...';

    if (aggResult.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }
    
    const dataProp = {
        aggResult,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
        uniqueFilter4,
    }

    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <ChartBuilderPanels
                        theme={theme}
                        dataProp={dataProp}
                        dynamicFilter={dynamicFilter}
                        attModalOpen={attModalOpen}
                        setOpen={setOpen}
                        filterParams={filterParams}
                        propObject={propObject}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                        panelContent={panelContent}
                        setPanelContent={setPanelContent}
                        currentReportConfig={currentReportConfig}
                        setCurrentReportConfig={setCurrentReportConfig}
                        flexConfigStorage={flexConfigStorage}
                        lastFlexUsed={lastFlexUsed}
                        newSettings={newSettings}
                        setNewSettings={setNewSettings}
                        reportList={reportList}
                        reportMap={reportMap}
                        handleStateChange={handleStateChange}
                    />

            </View>
        </View>

    )

}

export default ChartBuilderPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})