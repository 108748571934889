import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingComponent from '../Components/Loader.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import PivotTableContent from './PivotTableContent.js';
import PivotAdGroup from './CubeFunctions/PivotAdGroup.js';
import PivotDims from './CubeFunctions/PivotDims.js';
import CubeBudget from '../Budgets/CubeFunctions/CubeBudget.js';
import PivotPub from './CubeFunctions/PivotPub.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const PivotTablePanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentReportConfig = props.currentReportConfig
    const setCurrentReportConfig = props.setCurrentReportConfig
    const flexConfigStorage = props.flexConfigStorage
    const lastFlexUsed = props.lastFlexUsed
    const newSettings = props.newSettings
    const setNewSettings = props.setNewSettings
    const reportList = props.reportList
    const reportMap = props.reportMap
    const fmKey = props.fmKey
    const [panelContent, setPanelContent] = useState('summary')
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const clientID = props.clientID
    const attModalOpen = props.attModalOpen
    const setOpen = props.setOpen
    const client = clientID.toLowerCase();
    const cubeTable = 'glitnor_execSummary'
    const dynamicFilter = 'channel'

    const primaryMetric = currentMetrics.primaryMetric
    const secondaryMetric = currentMetrics.secondaryMetric
    const primaryRevenue = currentMetrics.primaryRevenue
    const primaryDimension = currentMetrics.primaryDimension


    let cubeTableDynamic;

    if (panelContent === 'summary') {
        cubeTableDynamic = `${client}_adgroup`
    } else if (panelContent === 'budget') {
        cubeTableDynamic = `${client}_budget`
    } else if (panelContent === 'publisher') {
        cubeTableDynamic = `${client}_publisher`
    }

    let dynamicFilter3;

    if (panelContent === 'summary') {
        dynamicFilter3 = 'campaign'
    } else if (panelContent === 'budget') {
        dynamicFilter3 = 'kw_type'
    } else if (panelContent === 'publisher') {
        dynamicFilter3 = 'kw_type'
    }

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = dynamicFilter3
    const filter4name = 'country'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')
    const [filter4List, setFilter4List] = useState('Total')

    const filterValueBase = {
        filter1List: [],
        filter2List: [],
        filter3List: [],
        filter4List: []
    }
    const [filterList, setFilterList] = useState(filterValueBase);


    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter4name,
        filter1List,
        filter2List,
        filter3List,
        filter4List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
        setFilter4List
    }

    const modelsBase = {
        model1: 'lt_lifetime',
        model2: 'ft_1',
        model3: 'pos_28'
    }

    const [models, setModels] = useState(modelsBase)

    function modelHandler(prop) {

        setModels(prop)
    }

    const dimensions = ['channel']

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 90);
    previousDate.setDate(1)
    const startDateString = previousDate.toISOString().slice(0, 10);


    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
    ]


    let cubeQueryDynamic;

    if (panelContent === 'summary') {
        cubeQueryDynamic = PivotAdGroup(cubeFilters, cubeTableDynamic, startDateString, yesterdayProp, filter1name, filter2name, filter3name)
    } else if (panelContent === 'budget') {
        cubeQueryDynamic = CubeBudget(cubeFilters, cubeTableDynamic, startDateString, yesterdayProp)
    } else if (panelContent === 'publisher') {
        cubeQueryDynamic = PivotPub(cubeFilters, cubeTableDynamic, startDateString, yesterdayProp)
    }

    const { resultSet } = useCubeQuery(cubeQueryDynamic);
    const dimQuery = PivotDims(cubeTableDynamic, filter1name, filter2name, filter3name, cubeFilters)
    const dimResult = useCubeQuery(dimQuery)


    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }

    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }


    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    const getUniqueValues = (arr, key) => {
        const uniqueSet = new Set(arr.map(item => item[key]));
        return [...uniqueSet];
    };



    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter1name)
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter2name)
    // repeat filters 1 & 2
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter1name)
    const uniqueFilter4 = ParseDimensionsAtt(dimData, cubeTableDynamic, filter2name)

    const aggResult = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTableDynamic + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });


    const updateVar = `${cubeTableDynamic}.updated_at`
    const lastUpdated = dimData?.[0]?.[updateVar] || 'loading...';

    if (aggResult.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }

    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    const dataProp = {
        aggResult,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
        uniqueFilter4,
        models
    }

    const pivotConfig = {
        data: aggResult,
        dataset: panelContent,
        fields: Object.keys(aggResult[0]),
        dim1: filter1name,
        dim2: filter2name,
        dim3: filter3name
    }


    return (
        <View style={styles.container}>

            <View style={{ width: '100%', height: '100%' }}>
                <PivotTableContent
                    theme={theme}
                    dataProp={dataProp}
                    dynamicFilter={dynamicFilter}
                    modelHandler={modelHandler}
                    attModalOpen={attModalOpen}
                    setOpen={setOpen}
                    filterParams={filterParams}
                    setFilterList={setFilterList}
                    filterList={filterList}
                    currentMetrics={currentMetrics}
                    panelContent={panelContent}
                    setPanelContent={setPanelContent}
                    pivotConfig={pivotConfig}
                    currentReportConfig={currentReportConfig}
                    setCurrentReportConfig={setCurrentReportConfig}
                    flexConfigStorage={flexConfigStorage}
                    lastFlexUsed={lastFlexUsed}
                    newSettings={newSettings}
                    setNewSettings={setNewSettings}
                    reportList={reportList}
                    reportMap={reportMap}
                    clientID={clientID}
                    fmKey={fmKey}
                />

            </View>
        </View>

    )

}

export default PivotTablePanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})