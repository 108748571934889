import React, { useState } from 'react';
import { View, StyleSheet, Dimensions } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader';
import { useCubeQuery } from '@cubejs-client/react';
import DocPanel from '../DocBuilder/DocPanel';
import docConfig from '../DocBuilder/docConfig.json'
import ParseConfig from '../DocBuilder/CubeFunctions/ParseConfig';
import CubeDailyFunction from '../DailySummary/CubeFunctions/CubeDailyFunction.js';
import ParseDimensions from '../CubeFunctions/ParseDimensions.js';
import moment from 'moment-timezone';
import NoDataContainer from '../Components/NoDataContainer.js';

const windowHeight = Dimensions.get('window').height;

const DocBuilder = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const tooltipArray = props.tooltipArray
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientID = configArray.clientID
  const client = clientID.toLowerCase();
  
  const cubeTable = client + "_daily";
  const theme = props.theme
  const homeTitle = "Presentation Builder";
  const role = configArray.role
  let clientText;
  if (clientID === 'PS') {
    clientText = 'Pepperstone'
  } else {
    clientText = client
  }

  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');

  const currentDate = new Date();

  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);

  function updateDates(data, newStartDate, newEndDate) {
    return data.map(item => {
        return {
            ...item,  // Spread the existing properties of the item
            start: newStartDate,  // Overwrite the start date
            end: newEndDate       // Overwrite the end date
        };
    });
}

const updatedConfig = updateDates(docConfig, startDateString, yesterdayProp);
  
  const [currentConfig, setCurrentConfig] = useState(JSON.parse((localStorage.getItem('docConfig'))) ?? updatedConfig)

  const dates = {
    startDateString,
    yesterdayProp
  }

  

  function updateSlide(prop) {
    const { currentSlide, currentVar, currentValue } = prop;

    // Create a new copy of the currentConfig array
    const updatedConfig = [...currentConfig];

    // If the slide exists, update the specific variable
    if (updatedConfig[currentSlide]) {
        updatedConfig[currentSlide] = {
            ...updatedConfig[currentSlide],
            [currentVar]: currentValue
        };
    }

    // Set the new state
    setCurrentConfig(updatedConfig);
    localStorage.setItem("docConfig", JSON.stringify(updatedConfig));
}

function updateDate(currentSlide, start, end) {
  
  // Create a new copy of the currentConfig array
  const updatedConfig = [...currentConfig];

  // If the slide exists, update the specific variable
  if (updatedConfig[currentSlide]) {
      updatedConfig[currentSlide] = {
          ...updatedConfig[currentSlide],
          'start': start,
          'end' : end
      };
  }

  // Set the new state
  setCurrentConfig(updatedConfig);
  localStorage.setItem("docConfig", JSON.stringify(updatedConfig));
}

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  function findDateRange(data) {
    let earliestStartDate = data[0].start;
    let latestEndDate = data[0].end;

    data.forEach(slide => {
      if (new Date(slide.start) < new Date(earliestStartDate)) {
        earliestStartDate = slide.start;
      }
      if (new Date(slide.end) > new Date(latestEndDate)) {
        latestEndDate = slide.end;
      }
    });

    return {
      start: earliestStartDate,
      end: latestEndDate
    };
  }

  const dateRange = findDateRange(currentConfig);

  const start = dateRange.start;
    const end = dateRange.end;


  const cubeFilters = [

]


const cubeQuery = ParseConfig(cubeFilters, cubeTable, start, end)
const { resultSet } = useCubeQuery(cubeQuery);
const dimQuery = CubeDailyFunction (cubeTable, start, end, 'brand', 'channel', 'kw_type', cubeFilters)
const dimResult = useCubeQuery(dimQuery)

if (!resultSet) {
  return (
      <LoadingComponent theme={theme} />
  );
}

if (dimResult.isLoading === true) {
  return (
      <LoadingComponent theme={theme} />
  );
}

    const yesterday = moment().subtract(1, 'days').format("YYYY-MM-DDT00:00:00.000");

    const brandProp = `${cubeTable}.brand`
    const channelProp = `${cubeTable}.channel`
    const clicksProp = `${cubeTable}.clicks`
    const dateProp = `${cubeTable}.date`
    const ftdsProp = `${cubeTable}.ftds`
    const impProp = `${cubeTable}.impressions`
    const typeProp = `${cubeTable}.kw_type`
    const regProp = `${cubeTable}.regs`
    const spendProp = `${cubeTable}.spend`
    const timestampProp = `${cubeTable}.timestamp`
    const timestampDayProp = `${cubeTable}.timestamp_day`
    

    const fallback = [{
        [brandProp]: "No Data",
        [channelProp]: "No Data",
        [clicksProp]: "0",
        [dateProp]: yesterday,
        [ftdsProp]: "0",
        [impProp]: "0",
        [typeProp]: "No Data",
        [regProp]: "0",
        [spendProp]: "0",
        [timestampProp]: yesterday,
        [timestampDayProp]: yesterday,
    }]


const cubeResponse = resultSet.loadResponse.results[0]?.data.length > 0 
    ? resultSet.loadResponse.results[0].data 
    : fallback;

const dimData = dimResult.resultSet.loadResponses[0].data
const uniqueData = ParseDimensions(dimData, cubeTable)

const uniqueBrandsBase = uniqueData.uniqueBrands;
const uniqueChannelsBase = uniqueData.uniqueChannels;
const uniqueTypesBase = uniqueData.uniqueTypes;
const uniqueBrands = ['Total', ...uniqueBrandsBase];
const uniqueChannels = ['Total', ...uniqueChannelsBase];
const uniqueTypes = ['Total', ...uniqueTypesBase];
const chartTypes = ['line', 'bar', 'mixed', 'table', 'cohorts']
const metrics = ['impressions', 'clicks', 'spend', 'regs', 'ftds', 'cpa']
const updateVar = `${cubeTable}.updated_at`

const lastUpdated = dimData?.[0]?.[updateVar] || 'loading...';

if (cubeResponse.length === 0) {
  return (
      <NoDataContainer
          theme={theme}
      />
  )
}


  return (

    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <DocPanel
        theme={theme}
        currentConfig={currentConfig}
        setCurrentConfig={setCurrentConfig}
        uniqueBrands={uniqueBrands}
        uniqueChannels={uniqueChannels}
        uniqueTypes={uniqueTypes}
        chartTypes={chartTypes}
        metrics={metrics}
        updateSlide={updateSlide}
        updateDate={updateDate}
        cubeResponse={cubeResponse}
        cubeTable={cubeTable}
        client={clientText}
        tooltipArray={tooltipArray}
        currentMetrics={currentMetrics}
        dates={dates}
      />


    </View>


  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 1,
    flexDirection: 'column',
    width: '100%',
    minHeight: windowHeight
  },
  panel: {
    width: '100%'
  }
})

export default DocBuilder