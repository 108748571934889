import { View, Dimensions, Text, Pressable } from 'react-native'
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;


const NotFound = (props) => {

    const theme = props.theme

    return (
        <View style={{
            width: windowWidth,
            height: windowHeight,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.backgroundColor
        }}>
            <View style={{
                width: '50%',
                height: '50%',
                borderColor: '#EF0107',
                borderWidth: 0,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ErrorTwoToneIcon style={{
                    color: 'red',
                    fontSize: windowWidth * 0.15
                }}/>

            </View>
            <View style={{
                width: '50%',
                height: '10%',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'colmumn'
            }}>
                <Text style={{
                    color: theme.backgroundColor2,
                    fontSize: windowWidth * 0.014,
                    textAlign: 'center',
                    fontFamily: 'Electrolize',
                }}>
                    Ooops, we can't find the page you're looking for.
                </Text>
                <Text style={{
                    color: theme.backgroundColor2,
                    fontSize: windowWidth * 0.014,
                    textAlign: 'center',
                    fontFamily: 'Electrolize',
                }}>
                    Please hit 'back' in your browser
                </Text>

            </View>
        
        </View>
    )

}

export default NotFound