import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import { LicenseInfo } from '@mui/x-license-pro';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExecSummaryMetricsTable from '../ExecSummaryMetrics';
import Button from '@mui/material/Button';


const ExecSummaryMetrics = (props) => {

    LicenseInfo.setLicenseKey('6bf1987e6f50457e0ccce5aee1489680Tz04MzczNyxFPTE3Mzg4NjE3NTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');
    const dataProp = props.dataProp
    const theme = props.theme
    const countryFilter = props.countryFilter
    const setCountryFilter = props.setCountryFilter


    const titleText = {
        fontFamily: 'Electrolize',
        color: theme.panelBackground,
        //fontSize: windowWidth * 0.015
    }

    const titleTextSelected = {
        fontFamily: 'Electrolize',
        color: theme.backgroundColor2,
        fontWeight: 'bold'
        //fontSize: windowWidth * 0.015
    }

    const AccordionComponent = ({ filterParams, dynamicFilter, currentMetrics }) => {
        return (
            < >
                <AccordionActions
                    //sx={{height: '2%', alignItems: 'center'}}
                    sx={{ border: `0px solid ${theme.backgroundColor2}` }}
                    >
                    <Button
                        onClick={() => setCountryFilter(['SWE', 'CHL', 'FIN'])}
                        style={countryFilter.length === 3 ? titleTextSelected : titleText}
                        >
                        Total
                    </Button>
                    <Button
                        onClick={() => setCountryFilter(['SWE'])}
                        style={countryFilter.length === 1 && countryFilter[0] === 'SWE' ? titleTextSelected : titleText}
                        >
                        SWE
                    </Button>
                    <Button
                        onClick={() => setCountryFilter(['FIN'])}
                        style={countryFilter[0] === 'FIN' ? titleTextSelected : titleText}
                        >
                        FIN
                    </Button>
                    <Button
                        onClick={() => setCountryFilter(['CHL'])}
                        style={countryFilter[0] === 'CHL' ? titleTextSelected : titleText}
                        >
                        CHL
                    </Button>
                </AccordionActions>
                {dataProp.map((item) => (
                    <Accordion key={item.id} sx={{ border: `3px solid ${theme.backgroundColor}` }}
                    //defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                            aria-controls={`${item.id}-content`}
                            id={`${item.id}-header`}
                            sx={{
                                color: 'white',
                                backgroundColor: theme.backgroundColor2,
                                fontFamily: 'Electrolize',
                                fontWeight: 'bold'
                            }}
                        >
                            {item.title}
                        </AccordionSummary>
                        <AccordionDetails sx={{
                            color: 'white',
                            backgroundColor: theme.backgroundColor,
                            fontFamily: 'Electrolize',
                            fontWeight: 'bold'
                        }}>
                            <ExecSummaryMetricsTable
                                dataProp={item.dataProp}
                                theme={theme}
                                filterParams={filterParams}
                                dynamicFilter={dynamicFilter}
                                currentMetrics={currentMetrics}
                                isAllPercent={item.isAllPercent}
                                itemId={item.id}
                            />
                        </AccordionDetails>
                    </Accordion>
                ))}
            </>
        );
    };

    return (
        <View style={styles.container}>
            <AccordionComponent />
        </View>
    )

}

export default ExecSummaryMetrics

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
        overflow: 'auto'
    }
})