import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    View,
    Dimensions,
    Text
} from 'react-native'

const windowWidth = Dimensions.get('window').width;

export default function OptionsGroupingHomeModal(props) {
    const open = props.open
    const theme = props.theme
    const setCurrentView = props.setCurrentView
    
    const handleClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            //setOpen(false);
        }
        setCurrentView('Definitions')
    };

    return (

        <div >
            <Dialog
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                sx={{width: '100%', height: '100%'}}

            >
                <DialogTitle sx={{ fontFamily: 'Electrolize' }}>Custom Grouping</DialogTitle>
                <DialogContent >
                    <Box component="form" sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',

                    }}>
                        <View style={{
                            width: '50%',
                            height: '100%',
                            padding: '2%',
                            alignItems: 'center',
                        }}>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{ fontWeight: 600, height: windowWidth * 0.075, width: windowWidth * 0.1, textAlign: 'center', fontFamily: 'Electrolize' }}
                        >
                            Custom Definitions
                        </Button>
                        </View>
                        <View style={{
                            width: '50%',
                            height: '100%',
                            padding: '2%'
                        }}>
                        <Button
                            //onClick={handleClose}
                            variant="contained"
                            sx={{ fontWeight: 600, 
                                height: windowWidth * 0.075, 
                                width: windowWidth * 0.1, 
                                textAlign: 'center', 
                                fontFamily: 'Electrolize',                                
                             }}
                            disabled={true}
                        >
                            Request Backend Updates
                        </Button>
                        </View>


                    </Box>

                </DialogContent>

            </Dialog>
        </div>

    );
}