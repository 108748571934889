import React from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text,
    Pressable
} from 'react-native';
import { useNavigate } from 'react-router-dom';

const windowWidth = Dimensions.get('window').width;

const OptionsModalClient = (props) => {

    const theme = props.theme
    const clientHandler = props.clientHandler
    const modalClientHandler = props.modalClientHandler
    const responsiveText = windowWidth * 0.01
    const navigate = useNavigate();

    function clickHandler (clientId, clientMapId) {
        modalClientHandler(false)
        clientHandler(clientId, clientMapId)
        navigate('/home')
    }

    const textTheme = {
        color: theme.color2,
        fontSize: responsiveText,
        textAlign: 'center',
        fontFamily: 'Electrolize',
        fontWeight: 600
    }

    const rowChild = {
        height: '100%',
        width: '50%',
        flexDirection: 'column',
        borderColor: theme.color2,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center'
    }

    return (
        <View style={styles.container}>
            <View style={styles.rowParent}>
            <Pressable 
                    style={[rowChild , {borderTopLeftRadius:10}]}
                    onPress={() => clickHandler("FM", "1006")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Fortunate Media
                        </Text>
                    </View>
                </Pressable>

                <Pressable 
                    style={[rowChild , {borderTopRightRadius:10}]}
                    onPress={() => clickHandler("Gamdom", "1002")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Gamdom
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {borderBottomLeftRadius:0}]}
                    onPress={() => clickHandler("Glitnor", "1003")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Glitnor
                        </Text>
                    </View>
                </Pressable>

                <Pressable 
                    style={[rowChild , {borderTopRightRadius:0}]}
                    onPress={() => clickHandler("Hero", "1005")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Hero
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild , {}]}
                    onPress={() => clickHandler("PS", "1007")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Pepperstone
                        </Text>
                    </View>
                </Pressable>
                <Pressable 
                    style={[rowChild , {}]}
                    onPress={() => clickHandler("Rank", "1004")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Rank International
                        </Text>
                    </View>
                </Pressable>
            </View>
            <View style={styles.rowParent}>
                <Pressable 
                    style={[rowChild ,  {borderBottomLeftRadius:10}]}
                    onPress={() => clickHandler("Test", "1001")}
                    
                    >

                    <View style={styles.childText}>
                        <Text style={textTheme}>
                            Test Data
                        </Text>
                    </View>
                </Pressable>
            </View>

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 10,
        flexDirection: 'column',
    },
    rowParent: {
        height: '25%',
        width: '100%',
        flexDirection: 'row'
    },
    childIcon: {
        width: '100%',
        height: '80%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    childText: {
        width: '100%',
        height: '20%',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

export default OptionsModalClient