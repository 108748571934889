export default function AnalyticsPathCubeDim (table, start, end, dim1, filters) {

    return {
        "order": {
            [`${table}.cost`]: "desc",
        },
        "limit": 1000,
        "dimensions": [
        `${table}.${dim1}`,
        `${table}.dim`,
        `${table}.updated_at`,
        ],
        "timeDimensions": [
          {
            "dimension": `${table}.timestamp`,
            "dateRange": [
              start,
              end
          ]
          }
        ],
        "filters" : filters
      }


}