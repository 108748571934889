import React, { useState } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Text
} from 'react-native'
import Button from '@mui/material/Button';


import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const windowWidth = Dimensions.get('window').width;
const windowheight = Dimensions.get('window').height;

const OptionsGroupingDefinitionsView = (props) => {
    const theme = props.theme
    const groupingData = props.groupingData.data
    const [edit, setEdit] = useState(false)
    const [dimension, setDimension] = useState(null)
    const [operator, setOperator] = useState(null)
    const [value, setValue] = useState(null)
    const [returnValue, setReturnValue] = useState(null)
    const dimensionOptions = ['brand', 'channel', 'campaign', 'adgroup']
    const operatorOptions = ['contains', 'equals']

    if (!groupingData) {
        return null
    }
    const custom_groupings = groupingData.custom_groupings

    const groupingMap = [
        {
            id: "custom_dimensions",
            title: "Custom Dimensions",
            dataProp: custom_groupings.custom_dimensions,
            isAllPercent: false
        },
        {
            id: "custom_metrics",
            title: "Custom Metrics",
            dataProp: custom_groupings.custom_metrics,
            isAllPercent: false
        }
    ]

    const dimensionsMap = dimensionOptions.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      ));

      const operatorMap = operatorOptions.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item}
        </MenuItem>
      ));

    const Dropdown = (props) => {

        //const setTextValue = props.setValue
        const value = props.currentValue
        const options = props.options
        const [textValue, setTextValue] = useState(value)
    
        return (
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center'
            }}>
                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, width: '100%', fontFamily: 'Electrolize', }}>
                    
                    <Select
                        labelId="demo-dialog-select-label"
                        id="demo-dialog-select"
                        value={textValue}
                        disabled={true}
                        sx={{fontFamily: 'Electrolize', 
                            //textAlign: 'center'
                        }}
    
    
                        onChange={(event) => setTextValue(event.target.value)}
                        input={<OutlinedInput />}
                    >
                        {options}
                    </Select>
                </FormControl>
    
            </Box>
        )
    }

    const TextBox = (props) => {
        const value = props.currentValue
        const options = props.options
        const [textValue, setTextValue] = useState(value)
    
        return (
            <Box component="form" sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
            }}>
                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15, width: '100%', fontFamily: 'Electrolize', }}>
                    
                <OutlinedInput 
                    id="outlined-search" 
                    onChange={e => setTextValue(e.target.value)}
                    type="search"
                    value={textValue}
                    disabled={true}
                    sx={{
                        width : '100%' ,
                        left : 0, 
                        fontFamily : 'Electrolize', 
                        color : theme.color , 
                        backgroundColor : theme.backgroundColor,
                        //border: `1px solid ${theme.backgroundColor2}`,
                        textAlign: 'center'
                        //height: 45,
                        //maxHeight: 40
                    }}
                    
                    />
                </FormControl>
    
            </Box>
        )
    }

    const GroupContainer = ({ child, index }) => {

        return (
            <View style={styles.groupContainer}>
                <View style={styles.groupHeader}>
                    <View style={{width: '93%'}}>
                    <Text style={{ fontFamily: 'Electrolize', 
                        color: theme.color, 
                        fontSize: windowWidth * 0.008, 
                        fontWeight: 600
                        }}>{child.id}</Text>
                    </View>
                    <View style={{width: '7%', alignItems: 'right', padding: '2px'}}>
                    <Button
                        variant="contained"
                        //onClick={() => setCountryFilter(['FIN'])}
                        //style={countryFilter[0] === 'FIN' ? titleTextSelected : titleText}
                        >
                        EDIT
                    </Button>
                    </View>
                </View>
                {child.dataProp.map((child, index) =>
                    <ChildContainer dataProp={Object.values(child)} id={Object.keys(child)} key={index}>
                    </ChildContainer>
                )}

            </View>
        )
    }

    const ChildContainer = ({ dataProp, id }) => {

        return (
            <View style={styles.childContainer} key={id}>
                <View style={styles.childContainerHeader}>
                    <Text>
                        {id}
                    </Text>
                </View>
                {dataProp.map((child, index, id) =>
                    <ChildItem
                    child={child}
                    index={index}
                    key={index}
                    />
                )}

            </View>
        )
    }

    const ChildItem = ({child, index}) => {
        console.log(child, index)

        const childItemStyle = {
            width: '25%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 0,
            alignItems: 'center',
            justifyContent: 'center',
        }

        const childHeaderStyle = {
            width: '25%',
            height: '100%',
            borderColor: 'grey',
            borderWidth: 1,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.backgroundColor2
        }

        const headerTextStyle = {
            fontFamily: 'Electrolize',
            color: 'white',
            fontWeight: 'bold',
            fontSize: windowWidth * 0.011
        }

        return (
            <View style={{flex: 1}}>
                <View style={styles.child}>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>
                            DIMENSION
                        </Text>
                    
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>
                            OPERATOR
                        </Text>                    
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>
                            VALUE
                        </Text> 
                    </View>
                    <View style={childHeaderStyle}>
                        <Text style={headerTextStyle}>
                            RETURN VALUE
                        </Text> 
                    </View>


                </View>
                {child.map((child, index) =>
                <View style={styles.child}>
                    <View style={childItemStyle}>
                        <Dropdown
                            setValue={setDimension}
                            currentValue={child.dimension}
                            options={dimensionsMap}
                        />
                    
                    </View>
                    <View style={childItemStyle}>                    
                        <Dropdown
                            setValue={setOperator}
                            currentValue={child.operator}
                            options={operatorMap}
                        />                   
                    </View>
                    <View style={childItemStyle}>
                        <TextBox
                            setValue={setValue}
                            currentValue={child.value}
                            options={operatorMap}
                        /> 
                    </View>
                    <View style={childItemStyle}>
                    <TextBox
                            setValue={setReturnValue}
                            currentValue={child.return_value}
                            options={operatorMap}
                        /> 
                    </View>


                </View>

                )}
            {edit && <View style={{width: "100%", alignItems: 'end', padding: '1%'}}>

            <View style={{width: '10%', alignItems: 'right', padding: '2px'}}>
                    <Button
                        variant="contained"
                        //onClick={() => setCountryFilter(['FIN'])}
                        //style={countryFilter[0] === 'FIN' ? titleTextSelected : titleText}
                        >
                        ADD CONDITION
                    </Button>
                    </View>

            </View>}
            

            </View>
        )

    }



    return (
        <View style={styles.container}>
            {groupingMap.map((child, index) =>
                <GroupContainer child={child} index={index} key={index}/>
            )}
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        //height: windowheight * 0.93,
        height: '100%',
        padding: '1%',
        borderWidth: 0,
        borderColor: 'yellow',
        //overflow: 'auto'

    },
    groupContainer: {
        width: '100%',
        //minHeight: windowWidth * 0.2,
        //minHeight: '45%',
        height: '75%',
        borderWidth: 2,
        borderColor: 'grey',
        padding: '1%',
        marginTop: '1%',
        flexDirection: 'column',
        overflow: 'auto'
    },
    groupHeader: {
        height: '5%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'red',
        alignItems: 'center',
        marginBottom: '1%',
        flexDirection: 'row'
    },
    childContainer: {
        width: '100%',
        borderWidth: 3,
        borderColor: 'grey',
        //minHeight: '100%',
        flexDirection: 'column',
        padding: '0.5%',
        marginBottom: '1%'

    },
    childContainerHeader: {
        width: '100%',
        height: '10%',
        
    },  
    child: {
        width: '100%',
        height: 75,
        borderWidth: 0,
        borderColor: 'red',
        flexDirection: 'row',
        padding: '0.5%'
    }
})

export default OptionsGroupingDefinitionsView