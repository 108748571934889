import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader';
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import HomeComparison from './CubeFunctions/HomeComparison';
import HomePanelV3 from './HomePanelV3';
import HomeBudget from './CubeFunctions/HomeBudget';
import NoDataContainer from '../Components/NoDataContainer.js';

const HomeScreen = (props) => {

  const configArray = props.configArray
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const currentMetrics = configArray.currentMetrics[0]
  const clientID = configArray.clientID
  const comparisonURL = configArray.comparison_url
  const clientBase = clientID.toLowerCase();
  let client;
  if (clientBase === 'all_clients') {
    client = 'stack'
  } else (client = clientBase)
  const cubeTable = clientBase + "_daily";
  const budgetTable = client + "_budget";
  const theme = props.theme
  const homeTitle = "Pattern Home";
  const role = configArray.role
  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const startOfLastMonth = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');

  const cumuDates = {
    start: yesterday2.format('YYYY-MM'),
    end: moment().subtract(1, 'month').startOf('month').format('YYYY-MM')
  }

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 30);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);

  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)
  }




  const cubeQuery = HomeComparison(cubeTable, startOfLastMonth, yesterdayProp)
  const { resultSet } = useCubeQuery(cubeQuery);
  const budgetCubeQuery = HomeBudget(budgetTable, startDateString, yesterdayProp)
  const budgetResultSet = useCubeQuery(budgetCubeQuery);

  if (!resultSet) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (budgetResultSet.isLoading === true) {
    return (
        <LoadingComponent theme={theme} />
    );
}

  const cubeResponse = resultSet.loadResponse.results[0].data
  const budgetCubeResponse = budgetResultSet.resultSet.loadResponse.results[0].data 

  const updateArray = cubeResponse.map(obj => {
    const newObj = {};
    for (const key in obj) {
      const newKey = key.replace(cubeTable + ".", "");
      newObj[newKey] = obj[key];
    }
    return newObj;
  });  

  const budgetArray = budgetCubeResponse.map(obj => {
    const newObj = {};
    for (const key in obj) {
      const newKey = key.replace(budgetTable + ".", "");
      newObj[newKey] = obj[key];
    }
    return newObj;
  });

  let lastUpdated;
  console.log(budgetArray.length)

  if (budgetArray.length === 0) {
    lastUpdated = 'loading...'
  } else if (budgetArray[0]['updated_at']) {
    lastUpdated = budgetArray[0]['updated_at'] 
  } else {
    lastUpdated = 'loading...'
  }

  //const lastUpdated = budgetArray[0]['updated_at'] || 'loading...'

  const agg_result = updateArray.map((obj) => {
    const date = moment.tz(obj.timestamp, 'Europe/Paris').format('YYYY-MM-DD');
    const spend = parseFloat(obj.spend)
    const ftds = parseInt(obj.ftds)
    const ngr = parseFloat(obj.ngr)
    const cpa = parseFloat((spend / ftds).toFixed(2))
    const clicks = parseInt(obj.clicks)
    const cpc = parseFloat((spend / clicks).toFixed(2))
    const ftdConv = ((parseFloat(ftds / clicks))*100).toFixed(2)

    return {
        date: date,
        clicks: clicks,
        spend: spend,
        cpc: cpc,
        ftds: ftds,
        ngr: ngr,
        cpa: cpa,
        clickConv: ftdConv

    };
});

  if (agg_result.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
  }

  return (

    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
    
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientID}
        lastUpdated={lastUpdated}
      />
    
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientID}
      />
      <HomePanelV3
        theme={theme}
        data={agg_result}
        clientID={clientID}
        comparisonURL={comparisonURL}
        budgetArray={budgetArray}
        cumuDates={cumuDates}
        currentMetrics={currentMetrics}
      />

    </View>


  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default HomeScreen