import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
    Pressable
} from 'react-native'
import FiltersEnhancedSingle_2 from '../../ComponentsEnhanced/FiltersEnhancedSingle_2';
import WebFont from 'webfontloader';
import AnalyticsEnhancedDataGrid from '../AnalyticsEnhancedDataGrid';
import AnalyticsEnhancedModal from '../AnalyticsEnhancedModal';
import TuneIcon from '@mui/icons-material/Tune';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AnalyticsEntryPanels = (props) => {

    const dataProp = props.dataProp
    const currentMetrics = props.currentMetrics
    const dataPropFilter = props.dataPropFilter
    const client = props.client
    const uniqueFilter1 = dataPropFilter.uniqueFilter1
    const uniqueFilter2 = dataPropFilter.uniqueFilter2
    const filterParams = props.filterParams
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const currentLevelBase = dataProp.agg_type_result
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const setCurrentConfig = props.setCurrentConfig
    const currentConfig = props.currentConfig
    const [open, setOpen] = useState(false)
    
    const fallback = [{
        channel: 'No Data',
        new_users: 0,
        sessions: 0,
        pageviews: 0,
        reg_start: 0,
        regs: 0,
        ftds: 0,
        ngr: 0,
        spend: 0,
        cpa: 0,
        month: 'No Data',
        id: 1
    }]

    const currentLevel = currentLevelBase.length > 0 ? currentLevelBase : fallback

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);


    const panelMain = {

        width: windowWidth,
        height: '99%',
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    let topValue, rightValue;

    if (windowWidth > 1800) {
        topValue = "1%"
        rightValue = "1%"
    } else {
        topValue = "1%"
        rightValue = "1%"
    }

    const headerToggleContainer = {
        position: 'absolute',
        right: rightValue,
        top: topValue,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 100000,
        flexDirection: 'row',
        fontFamily: 'Electrolize'
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                    <FiltersEnhancedSingle_2
                        style={{
                            height: '10%',
                            borderColor: 'red',
                            borderWidth: 0,
                            width: '100%',
                            fontFamily: 'Electrolize'
                        }}
                        uniqueFilter1={uniqueFilter1}
                        uniqueFilter2={uniqueFilter2}
                        handleStateChange={handleStateChange}
                        propObject={propObject}
                        filterParams={filterParams}
                        theme={theme}
                        large={1}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        currentMetrics={currentMetrics}
                    />
                    </View>
                    <View style={{width: '99%'}}>
                    <Pressable 
                        style={headerToggleContainer}
                        onPress={() => setOpen(true)}
                        >
                            <TuneIcon
                                style={{
                                    color: theme.backgroundColor2,
                                    fontSize: windowWidth * 0.02
                                }}
                            />

                    </Pressable>
                        <AnalyticsEnhancedDataGrid                            
                            dataProp={currentLevel}
                            theme={theme}
                            currentMetrics={currentMetrics}
                            client={client}
                        />



                    </View>
                        <AnalyticsEnhancedModal
                            open={open}
                            setOpen={setOpen}
                            currentConfig={currentConfig}
                            setCurrentConfig={setCurrentConfig}
                            currentMetrics={currentMetrics}
                        />

                </View>
            </View>

        </View>

    )

}

export default AnalyticsEntryPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        minHeight: (windowHeight * 0.93),
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    }
})