export default function SupportTicketCube(cubeFilters, table) {

    return {
        "filters": cubeFilters,
        "limit": 5000,
        "dimensions": [
            `${table}.client`,
            `${table}.userNickname`,
            `${table}.user_type`,
            `${table}.ticket_type`,
            `${table}.report_type`,
            `${table}.critical_bool`,
            `${table}.description`,
            `${table}.current_case_status`
        ],
        "timeDimensions": [
            {
              
              "dimension": `${table}.timestamp`,
              "granularity": "day"
            }
          ],
          "timezone": "Europe/Malta",
        "order": { [`${table}.timestamp`]: "desc" }
        
        
        
    }

}