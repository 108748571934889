import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native'
import AppBarRN from '../Components/AppBarRN';
import TemporaryDrawer from "../Components/Drawer";
import LoadingComponent from '../Components/Loader'
import moment from 'moment-timezone';
import { useCubeQuery } from '@cubejs-client/react';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import ProgPanels from '../Programmatic/ProgPanels';
import ProgCubeQuery from './ProgCubeQuery';
import CubeDailyFunction from '../DailySummary/CubeFunctions/CubeDailyFunction.js';
import NoDataContainer from '../Components/NoDataContainer.js';

const ProgrammaticChannel = (props) => {

  const configArray = props.configArray
  const currentMetrics = configArray.currentMetrics[0]
  const configHandler = configArray.configHandler
  const clientHandler = configArray.clientHandler
  const clientUpper = configArray.clientID
  const client = clientUpper.toLowerCase();
  const cubeTable = client + "_publisher"
  const theme = props.theme
  const homeTitle = "MTD DSPs";
  const role = configArray.role

  const currentDate2 = moment.tz('Europe/Malta');
  const yesterday2 = currentDate2.clone().subtract(1, 'day');
  const yesterdayProp = yesterday2.format('YYYY-MM-DD');
  const chartType = "channel"

  const currentDate = new Date();
  currentDate.setMinutes(0);
  const previousDate = new Date(currentDate);
  previousDate.setDate(currentDate.getDate() - 1);
  previousDate.setDate(1)

  const startDateString = previousDate.toISOString().slice(0, 10);

  const filter1name = 'brand'
  const filter2name = 'channel'
  const filter3name = 'kw_type'

  const [filter1List, setFilter1List] = useState('Total')
  const [filter2List, setFilter2List] = useState('Total')
  const [filter3List, setFilter3List] = useState('Total')

  const filterValueBase = {
    filter1List : [],
    filter2List : [],
    filter3List : []
  }
const [filterList, setFilterList] = useState(filterValueBase);

  const filterParams = {
    filter1name,
    filter2name,
    filter3name,
    filter1List,
    filter2List,
    filter3List,
    setFilter1List,
    setFilter2List,
    setFilter3List,
  }

  const filterBase = {
    start: startDateString,
    end: yesterdayProp,
    [filter1name]: "Total",
    [filter2name]: 'Total',
    [filter3name]: "Total",
  };


  const stateBase = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  const [state, setState] = useState(stateBase);
  const anchor = 'left'

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });

  };

  function handleDrawer(anchor) {

    const stateChange = {
      top: false,
      left: true,
      bottom: false,
      right: false,
    };
    setState(stateChange)

  }

  

  const [filters, setFilters] = useState(filterBase);

  const filterStartDate = filters.start
  const filterEndDate = filters.end

  let filter1Filter, filter1Operator;

  if (filters[filter1name] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name].length === 0) {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else if (filters[filter1name][0] === 'Total') {
    filter1Filter = ['null']
    filter1Operator = ['notEquals']
  } else {
    filter1Filter = filter1List
    filter1Operator = ['equals']
  }

  let filter2Filter, filter2Operator;

  if (filters[filter2name] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name].length === 0) {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else if (filters[filter2name][0] === 'Total') {
    filter2Filter = ['null']
    filter2Operator = ['notEquals']
  } else {
    filter2Filter = filter2List
    filter2Operator = ['equals']
  }

  let filter3Filter, filter3Operator;

  if (filters[filter3name] === 'Total') {
    filter3Filter = ['null']
    filter3Operator = ['notEquals']
  } else if (filters[filter3name].length === 0) {
    filter3Filter = ['null']
    filter3Operator = ['notEquals']
  } else if (filters[filter3name][0] === 'Total') {
    filter3Filter = ['null']
    filter3Operator = ['notEquals']
  } else {
    filter3Filter = filter3List
    filter3Operator = ['equals']
  }


  const propObjectBase = {
    data: [],
    start: startDateString,
    end: yesterdayProp,
    theme: theme,
    [filter1name]: "Total",
    [filter2name]: 'Total',
    [filter3name]: "Total",
  };


  const [propObject, setpropObject] = useState(propObjectBase);

  const handleStateChange = (prop) => {
    setpropObject(prop);

    const filterUpdate = {
      start: prop.start,
      end: prop.end,
      [filter1name]: prop[filter1name],
      [filter2name]: prop[filter2name],
      [filter3name]: prop[filter3name],
    };

    setFilters(filterUpdate)

  };

  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    id: false,
    impressions: true,
    clicks: false,
    ctr: true,
    spend: true,
    regs: true,
    ftds: true,
    ngr: true,
    cpa: true,
  });

  const cubeFilters = [
    {
        "member": `${cubeTable}.${filter1name}`,
        "operator": `${filter1Operator}`,
        "values": filter1Filter
    },
    {
        "member": `${cubeTable}.${filter2name}`,
        "operator": `${filter2Operator}`,
        "values": filter2Filter
    },
    {
        "member": `${cubeTable}.${filter3name}`,
        "operator": `${filter3Operator}`,
        "values": filter3Filter
    }
]

  const uniqueDimensionQuery = CubeDailyFunction (cubeTable, filterStartDate, filterEndDate, filter1name, filter2name, filter3name, cubeFilters)
  const { resultSet } = useCubeQuery(uniqueDimensionQuery);
  const cubeQuery = ProgCubeQuery(cubeFilters, cubeTable, filterStartDate, filterEndDate, "channel")
  const dataCubeResponse = useCubeQuery(cubeQuery);


  if (!resultSet) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  if (dataCubeResponse.isLoading === true) {
    return (
      <LoadingComponent theme={theme} />
    );
  }

  const cubeResponse = resultSet.loadResponse.results[0].data
  const dataCube = dataCubeResponse.resultSet.loadResponse.results[0].data

  const uniqueFilter1 = ParseDimensionsAtt(cubeResponse, cubeTable, filter1name);
  const uniqueFilter2 = ParseDimensionsAtt(cubeResponse, cubeTable, filter2name);
  const uniqueFilter3 = ParseDimensionsAtt(cubeResponse, cubeTable, filter3name);

  const updateVar = `${cubeTable}.updated_at`
  const lastUpdated = cubeResponse?.[0]?.[updateVar] || 'loading...';

  if (dataCube.length === 0) {
    return (
        <NoDataContainer
            theme={theme}
        />
    )
}


  const dataProp = {
    aggResult : dataCube,
    uniqueFilter1,
    uniqueFilter2,
    uniqueFilter3,
}

  return (
    <View style={[styles.container, { backgroundColor: theme.backgroundColor }]}>
      <AppBarRN
        handleDrawer={handleDrawer}
        homeTitle={homeTitle}
        theme={theme}
        configHandler={configHandler}
        clientHandler={clientHandler}
        role={role}
        client={clientUpper}
        lastUpdated={lastUpdated}
      />
      <TemporaryDrawer
        toggleDrawer={toggleDrawer}
        state={state}
        anchor={anchor}
        theme={theme}
        clientID={clientUpper}
      />
      <ProgPanels
        theme={theme}
        dataProp={dataProp}
        propObject={propObject}
        handleStateChange={handleStateChange}
        filterParams={filterParams}
        dataPropProg={dataProp}
        filters={filters}
        cubeTable={cubeTable}
        chartType={chartType}
        cubeResponse={dataCube}
        cubeDim={'channel'}
        setFilterList={setFilterList}
        filterList={filterList}
        currentMetrics={currentMetrics}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
      />

    </View>

  )
}



const styles = StyleSheet.create({
  container: {
    borderWidth: 0,
    borderColor: 'yellow',
    paddingTop: 0,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '100%',
    flex: 1,
  },
  panel: {
    width: '100%'
  }
})

export default ProgrammaticChannel