import React, { useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import ReactivationsDataGrid from './ReactivationsDataGrid.js';
import WebFont from 'webfontloader';
import FiltersEnhancedSingle from '../ComponentsEnhanced/FiltersEnhancedSingle.js';

const windowHeight = Dimensions.get('window').height;

const ReactivationsPanels = (props) => {

    const currentMetrics = props.currentMetrics
    const dataProp = props.dataPropChannel
    const uniqueFilter1 = dataProp.uniqueFilter1
    const uniqueFilter2 = dataProp.uniqueFilter2
    const uniqueFilter3 = dataProp.uniqueFilter3
    const filterParams = props.filterParams
    const theme = props.theme
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const currentLevel= props.dataProp
    const currentChannel = props.currentChannel
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const columnVisibilityModel = props.columnVisibilityModel
    const setColumnVisibilityModel = props.setColumnVisibilityModel

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const panelMain = {

        width: '100%',
        height: '100%',
        minHeight: windowHeight * 0.93,
        borderWidth: 0.1,
        borderColor: 'grey',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 40,
        fontFamily: 'Electrolize',
        borderRadius: 5
    }

    return (
        <View style={styles.container}>
            <View style={{ flexDirection: 'row' }}>
                <View style={panelMain}>
                    <View style={{ width: '100%', flexDirection: 'flex-start', fontFamily: 'Electrolize' }}>
                        <FiltersEnhancedSingle
                            style={{
                                height: '10%',
                                borderColor: 'red',
                                borderWidth: 0,
                                width: '100%',
                                fontFamily: 'Electrolize'
                            }}
                            uniqueFilter1={uniqueFilter1}
                            uniqueFilter2={uniqueFilter2}
                            uniqueFilter3={uniqueFilter3}
                            handleStateChange={handleStateChange}
                            propObject={propObject}
                            filterParams={filterParams}
                            theme={theme}
                            typeFilterState={false}
                            brandFilterState={false}
                            channelFilterState={true}
                            currentChannel={currentChannel}
                            large={1}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            currentMetrics={currentMetrics}
                        />
                    </View>
                    <View style={{ flexDirection: 'row', flex: 1, width: '98%', borderColor: 'red', borderWidth: 0 }}>
                        <ReactivationsDataGrid
                            dataProp={currentLevel}
                            theme={theme}
                            currentChannel={currentChannel}
                            currentMetrics={currentMetrics}
                            columnVisibilityModel={columnVisibilityModel}
                            setColumnVisibilityModel={setColumnVisibilityModel}
                        />
                    </View>
                </View>

            </View>
        </View>
    )
}

export default ReactivationsPanels

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',


    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})