import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Dimensions, View } from 'react-native'
import { useState } from 'react'

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const windowWidth = Dimensions.get('window').width;

export default function AnalyticsEnhancedModal(props) {
  const open = props.open
  const setOpen = props.setOpen
  const currentConfig = props.currentConfig
  const setCurrentConfig = props.setCurrentConfig
  const currentMetrics = props.currentMetrics

  const currentType = currentConfig.type
  const currentDim = currentConfig.dim

  const [typeValue, setTypeValue] = useState(currentType)
  const [dimValue, setDimValue] = useState(currentDim)

  const textHandler = (value) => {
    let output;
    if (value === 'ftds') {
      output = currentMetrics.primaryMetric
    } else if (value === 'regs') {
      output = currentMetrics.secondaryMetric
    } else if (value === 'ngr') {
      output = currentMetrics.primaryRevenue
    } else {
      output = value
    }
    return output
  }

  

  let typeDisabled, dimensionDisabled, metric1Disabled, metric2Disabled;

  if (typeValue === 'line') {
    typeDisabled = false;
    dimensionDisabled = false;
    metric1Disabled = false;
    metric2Disabled = true
  } else if (typeValue === 'bar') {
    typeDisabled = false;
    dimensionDisabled = false;
    metric1Disabled = false;
    metric2Disabled = true
  } else if (typeValue === 'mixed') {
    typeDisabled = false;
    dimensionDisabled = false;
    metric1Disabled = false;
    metric2Disabled = false
  } else if (typeValue === 'table') {
    typeDisabled = false;
    dimensionDisabled = false;
    metric1Disabled = true;
    metric2Disabled = true
  }
  

  const chartTypes = ['Total', 'Non-Customer', 'New Customer', 'Existing Customer']
  const dimTypes = ['brand', 'channel', 'entry_page', 'user_type', 'content_category']

  const dimFormat = (value) => {
    if (value === 'brand') {
        return 'Brand'
    } else if (value === 'channel') {
        return 'Channel'
    } else if (value === 'entry_page') {
        return 'Entry Page'
    } else if (value === 'content_category') {
        return 'Content Category'
    }  else {
        return 'User Type'
    }
  }
  
  const chartOptions = chartTypes.map((item, index) => (
    <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
      {item}
    </MenuItem>
  ));

  const dimOptions = dimTypes.map((item, index) => (
    <MenuItem key={index} value={item} sx={{fontFamily: 'Electrolize'}}>
      {dimFormat(item)}
    </MenuItem>
  ));

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
    console.log(reason)
  };

  function handleSave () {
    //setMetric(textValue)

    const currentState = {
        type: typeValue,
        dim: dimValue,
    }

    setCurrentConfig(currentState)


    setOpen(false);
  }




  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose} maxWidth={1500}>
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Configure Chart</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    }}>
                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
                <InputLabel id="demo-dialog-select-label" sx={{fontFamily: 'Electrolize'}}>Dimension</InputLabel>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={dimValue}
                    disabled={dimensionDisabled}
                    sx={{fontFamily: 'Electrolize'}}
                    onChange={(event) => setDimValue(event.target.value)}
                    input={<OutlinedInput label="Dimension" />}
                >
                    {dimOptions}
                </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: windowWidth * 0.15,fontFamily: 'Electrolize',}}>
                <InputLabel id="demo-dialog-select-label" sx={{fontFamily: 'Electrolize'}}>User Type</InputLabel>
                <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={typeValue}
                    disabled={typeDisabled}
                    sx={{fontFamily: 'Electrolize'}}

                    onChange={(event) => setTypeValue(event.target.value)}
                    input={<OutlinedInput label="User Type" />}
                >
                    {chartOptions}
                </Select>
                </FormControl>
                
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Update</Button>
        </DialogActions>
      </Dialog>
    </div>
    </View>
  );
}