import React, { useState } from 'react';
import { View, 
    StyleSheet
} from 'react-native'
import OptionsGroupingHomeModal from './OptionsGroupingHome/OptionsGroupingHomeModal';
import OptionsGroupingDefinitions from './OptionsGroupingDefinitions/OptionsGroupingDefinitions';

const OptionsGroupingPanels = (props) => {

    const theme = props.theme
    const updateArray = props.updateArray
    const currentMetrics = props.currentMetrics
    const configArray = props.configArray
    
    const [currentView, setCurrentView] = useState('Home')
    const homeopen = currentView === 'Home'


    const renderComponentContent = () => {
        if (currentView === 'Home') {
            return null;
        } else if (currentView === 'Definitions') {
            return <OptionsGroupingDefinitions
                theme={theme}
                configArray={configArray}
                updateArray={updateArray}
                currentMetrics={currentMetrics}
            />;
        } else {
            return null;
        }
    };


    return (
        <View style={styles.container}>
            <OptionsGroupingHomeModal
            open={homeopen}
            setCurrentView={setCurrentView}
            theme={theme}
            />
            {renderComponentContent()}

        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'red',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        //backgroundColor: 'white'
    }
})

export default OptionsGroupingPanels