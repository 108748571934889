export default function CountryCubeFunction(cubeFilters, table, start, end) {

    return {
        "filters": cubeFilters,
        "limit": 5000,
        "measures": [
            `${table}.impressions`,
            `${table}.clicks`,
            `${table}.spend`,
            `${table}.regs`,
            `${table}.ftds`,
            `${table}.ngr`,
        ],
        "dimensions" : [
            `${table}.brand`,
            `${table}.kw_type` 
        ],
        "timeDimensions": [
            {
              "dateRange": [
                start,
                end
              ],
              "dimension": `${table}.timestamp`,
              "granularity": "day"
            }
          ],
          //"timezone": "Europe/Malta",
        "order": [
            [
                `${table}.timestamp`,
                "asc"
            ]
        ],
        
        
        
    }

}