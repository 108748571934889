import React, { useState } from 'react';
import { View, 
    StyleSheet, 
    Dimensions,
    Text } from 'react-native'
import SupportForm from './SupportForm';
import SupportModal from './SupportModal';
import SupportLoader from './SupportLoader';
import { postToSignedUrl } from './SupportSigner';
import signRequest  from './SupportSigner';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const SupportPanel = (props) => {

    const theme = props.theme
    const configArray = props.configArray
    const support_url = configArray.support_url
    const support_key = configArray.support_key
    const support_id = configArray.support_id
    const [submitted, setSubmitted] = useState(false)
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);


    const sendDataToLambda = async (event_data, support_url, support_key, support_id) => {
        setLoading(true); // Start loading
        setResponse(null); // Clear previous response
    
        try {
          // First, get the signed URL
          const signedUrl = await signRequest(event_data, support_url, support_key, support_id);
    
          // Send the POST request to the signed URL
          const result = await postToSignedUrl(signedUrl, event_data);
          //setLoading(false)
          //setSubmitted(true)
        } catch (error) {
          console.error("Error posting to Lambda:", error);
          //setResponse({ statusCode: "error", data: null });
        } finally {
          setLoading(false); // Stop loading after request completes
          setSubmitted(true)
        }
      };

    const titleText = {
        fontFamily: 'Electrolize',
        fontSize: windowWidth * 0.015,
        color: '#0a2342',
        fontWeight: 600
    }

    const saveHandler = (config) => {
        const event_data = {
            'event': 'support_submission',
            date: config.date || null,
            client: config.client || null,
            userName: config.userName || null,
            userNickname: config.userNickname || null,
            typeValue: config.typeValue || null,
            reportValue: config.reportValue || null,
            criticalBool: config.criticalBool || null,
            descriptionValue: config.descriptionValue || null,
            status : 'New'
        }

        sendDataToLambda(event_data, support_url, support_key, support_id)
    }

    return (
        <View style={styles.container}>
                <View style={styles.titlePanel}>
                    <Text style={titleText}>
                        Open a support case:
                    </Text>
                </View>
                <View style={styles.formPanel}>
                    <SupportForm
                        theme={theme}
                        configArray={configArray}
                        saveHandler={saveHandler}
                    />

                </View>
            <SupportModal
                open={submitted}
                setOpen={setSubmitted}
            />
            <SupportLoader
                open={loading}
                setOpen={setLoading}
                theme={theme}
            />
            
        </View>
    )

}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderColor: 'grey',
        paddingTop: 0,
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        height: (windowHeight * 0.93),
        backgroundColor: 'white'
        //flex: 1,
    },
    titlePanel: {
        width: '80%',
        height: '10%',
        borderWidth: 0,
        borderColor: 'blue',
        justifyContent: 'center',
        alignItems: 'center',

    },
    formPanel: {
        width: '80%',
        height: '90%'
    }
    
})

export default SupportPanel