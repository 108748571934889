import React from 'react'
import { View, StyleSheet, Dimensions, Text } from 'react-native'

const windowWidth = Dimensions.get('window').width;

const CountryMetrics = (props) => {

    const agg_daily_result = props.dataProp
    const theme = props.theme
    const currentMetrics = props.currentMetrics

    

    let totalSpend = 0;
    for (let i in agg_daily_result) {
        totalSpend += agg_daily_result[i].spend
    }

    let totalFTD = 0;
    for (let i in agg_daily_result) {
        totalFTD += agg_daily_result[i].ftds
    }

    let totalReg = 0;
    for (let i in agg_daily_result) {
        totalReg += agg_daily_result[i].regs
    }

    let totalNgr = 0;
    for (let i in agg_daily_result) {
        totalNgr += agg_daily_result[i].ngr
    }

    const cpa = totalSpend === 0 || totalFTD === 0 || isNaN(totalFTD) ? 0 : totalSpend / totalFTD;
    const roas = totalSpend === 0 || totalNgr === 0 || isNaN(totalNgr) ? 0 : (totalNgr / totalSpend) * 100;

    const totalCPA = "€"+parseFloat((cpa).toFixed(0)).toLocaleString();
    const spendTotal = "€"+parseFloat(totalSpend.toFixed(0)).toLocaleString();
    const regFormat = parseFloat(totalReg.toFixed(0)).toLocaleString();
    const ftdFormat = parseFloat(totalFTD.toFixed(0)).toLocaleString();
    const ngrFormat = "€"+parseFloat((totalNgr).toFixed(0)).toLocaleString();
    const roasFormat = parseFloat((roas).toFixed(2)).toLocaleString()+"%";

    const metricList = [
        {
            metric: spendTotal,
            name: 'spend'
        },
        {
            metric: regFormat,
            name: 'regs'
        },
        {
            metric: ftdFormat,
            name: 'ftds'
        },
        {
            metric: ngrFormat,
            name: 'ngr'
        },
        {
            metric: totalCPA,
            name: 'cpa'
        },
        {
            metric: roasFormat,
            name: 'roas'
        }]

    const metricMap = metricList.map((item, index) => (
        <MetricComponent
            theme={theme}
            metric={item.metric}
            metricTitle={item.name}
            currentMetrics={currentMetrics}
        />

    ))

    return (
        <View style={styles.container}>
            {metricMap}
        </View>
    )

}

const MetricComponent = (props) => {

    const theme = props.theme
    const metric = props.metric
    const metricTitle = props.metricTitle
    const currentMetrics = props.currentMetrics
    const responsiveLarge = windowWidth * 0.019
    const responsiveSmall = windowWidth * 0.01

    let newLabel;
    if (metricTitle === 'ftds') {
        newLabel = currentMetrics.primaryMetric
    } else if (metricTitle === 'ftd') {
        newLabel = currentMetrics.primaryMetric
    } else if (metricTitle === 'regs') {
        newLabel = currentMetrics.secondaryMetric
    } else if (metricTitle === 'ftd_ngr') {
        newLabel = currentMetrics.primaryRevenue
    } else if (metricTitle === 'kw_type') {
        newLabel = currentMetrics.primaryDimensionString.toLowerCase()
    } else { newLabel = metricTitle }

    return (
        <View style={{
            width: '16.6666667%',
            height: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 0.1,
            borderColor: 'grey'
        }}
        >
            <View style={{
                width: '100%',
                height: '15%',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Text style={{ alignText: 'center', color: theme.color, fontSize: responsiveSmall, fontFamily: 'Electrolize', }}>
                    {newLabel}
                </Text>
            </View>
            <View style={{
                width: '100%',
                height: '65%',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Text style={{ alignText: 'center', color: theme.color, fontSize: responsiveLarge, fontFamily: 'Electrolize', }}>
                    {metric}
                </Text>
            </View>


        </View>
    )

}

export default CountryMetrics

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        fontFamily: 'Electrolize',
        filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.05))',
    }
})