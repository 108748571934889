import React, { useState , useEffect } from 'react';
import { View , 
    StyleSheet , 
    Dimensions , 
    Text,
    Pressable
} from 'react-native'
import FiltersEnhancedPaths from '../../ComponentsEnhanced/FiltersEnhancedPaths';
import AnalyticsEventsDataGrid from './AnalyticsEventsDataGrid';

const windowWidth = Dimensions.get('window').width;
const windowHeight = Dimensions.get('window').height;

const AnalyticsEventsPanels = (props) => {

    const theme = props.theme
    const dataProp = props.dataProp
    const filterLabels = props.filterLabels
    const handleStateChange = props.handleStateChange
    const propObject = props.propObject
    const filterParams = props.filterParams
    const filterList = props.filterList
    const setFilterList = props.setFilterList
    const currentMetrics = props.currentMetrics
    const primaryMetric = currentMetrics.primaryMetricString
    const primaryRevenue = currentMetrics.primaryRevenueString
    const columnVisibilityModel = props.columnVisibilityModel
    const setColumnVisibilityModel = props.setColumnVisibilityModel

    const [textInput, setTextInput] = useState("")

    function filterJsonObject(jsonObject, textInput) {
        if (textInput === '') {
            return jsonObject;
        }
        return jsonObject.filter(item => {
            // Check if any property values contain the text input
            return Object.values(item).some(value => {
                if (Array.isArray(value)) {
                    return value.some(subValue => subValue.toLowerCase().includes(textInput.toLowerCase()));
                }
                return typeof value === 'string' && value.toLowerCase().includes(textInput.toLowerCase());
            });
        });
    }

    const outputList = filterJsonObject(dataProp, textInput)

    

    
    return (
        <View style={styles.container}>
            <View style={styles.filterContainer}>
            <FiltersEnhancedPaths
                style={{
                    height: '10%',
                    borderColor: 'red',
                    borderWidth: 0,
                    width: '100%',
                    fontFamily: 'Electrolize'
                }}
                uniqueFilter1={filterLabels}
                handleStateChange={handleStateChange}
                propObject={propObject}
                filterParams={filterParams}
                theme={theme}
                setFilterList={setFilterList}
                filterList={filterList}
                textInput={textInput}
                setTextInput={setTextInput}
                large={1}
                singleModel={true}
            />

            </View>
            <View style={styles.bodyContainer}>
                <AnalyticsEventsDataGrid
                    dataProp={outputList}
                    theme={theme}
                    columnVisibilityModel={columnVisibilityModel}
                    setColumnVisibilityModel={setColumnVisibilityModel}
                    currentMetrics={currentMetrics}
                />

                
            </View>
        </View>
    )

}

export default AnalyticsEventsPanels

const styles = StyleSheet.create({
    container: {
      borderWidth: 0,
      borderColor: 'blue',
      height : windowHeight * 0.93,
      width : '100%',
      flexDirection : 'column',
      fontFamily : 'Electrolize',
      padding: '0%'
    },
    filterContainer: {
        height: '10%',
        width: '100%',
        borderColor: 'red',
        borderWidth: 0,
    },
    bodyContainer: {
        height: '90%',
        width: '100%',
        borderWidth: 0,
        borderColor: 'blue',
        flexDirection : 'column',
        padding: '1%',
    }
})