import * as React from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { View, 
    Dimensions } from 'react-native'
import SupportLoaderComponent from './SupportLoaderComponent';

const windowWidth = Dimensions.get('window').width;

export default function SupportLoader(props) {
  const open = props.open
  const setOpen = props.setOpen
  const theme = props.theme

  return (
    <View style={{width: windowWidth * 0.8, borderColor: 'red', borderWidth: 0, height: '50%'}}>
    <div>
      <Dialog disableEscapeKeyDown open={open} >
        <DialogTitle sx={{fontFamily: 'Electrolize'}}>Sending Support Case...</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ 
                                    display: 'flex', 
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                    }}>
            <SupportLoaderComponent theme={theme}/>

                
          </Box>
          
        </DialogContent>
      </Dialog>
    </div>
    </View>
  );
}