import React, { useState, useEffect } from 'react';
import {
    View,
    StyleSheet,
    Dimensions,
} from 'react-native'
import WebFont from 'webfontloader';
import moment from 'moment-timezone';
import CampaignSummaryView from './CubeFunctions/CampaignSummaryView.js';
import LoadingComponent from '../Components/Loader';
import { useCubeQuery } from '@cubejs-client/react';
import CubeDailyFunction from '../DailySummary/CubeFunctions/CubeDailyFunction.js';
import ParseDaily from '../CubeFunctions/ParseDaily.js';
import ParseDimensionsAtt from '../AttributionEnhanced/CubeFunctions/ParseDimensionsAtt.js';
import NoDataContainer from '../Components/NoDataContainer.js';

import CubeCampaign from './CubeFunctions/CubeCampaign.js';

const windowHeight = Dimensions.get('window').height;

const CampaignSummaryPanel = (props) => {

    const theme = props.theme
    const currentMetrics = props.currentMetrics
    const setLastUpdated = props.setLastUpdated
    const currentDate2 = moment.tz('Europe/Malta');
    const yesterday2 = currentDate2.clone().subtract(1, 'day');
    const yesterdayProp = yesterday2.format('YYYY-MM-DD');
    const homeTitle = "PPC Campaigns";
    const currentChannel = "PPC"
    const clientID = props.clientID
    const client = clientID.toLowerCase();
    const cubeTable = client + "_daily_campaign";
    const [filtered, setFiltered] = useState(false)

    const currentDate = new Date();

    // Set the timezone offset to 0 to work with local time
    currentDate.setMinutes(0);
    const previousDate = new Date(currentDate);
    previousDate.setDate(currentDate.getDate() - 1);
    previousDate.setDate(1)

    const thisMonthDateString = previousDate.toISOString().slice(0, 10);

    const newCurrentDate = new Date();
    newCurrentDate.setMonth(newCurrentDate.getMonth() - 2); // Move back two months
    newCurrentDate.setDate(1); // Set to the first day of the month

    //const startDateString = newCurrentDate.toISOString().slice(0, 10);

    const startDateString = "2024-03-01"

    const filter1name = 'brand'
    const filter2name = 'channel'
    const filter3name = 'campaign'

    const [filter1List, setFilter1List] = useState('Total')
    const [filter2List, setFilter2List] = useState('Total')
    const [filter3List, setFilter3List] = useState('Total')

    const filterValueBase = {
        filter1List : [],
        filter2List : [],
        filter3List : []
      }
    const [filterList, setFilterList] = useState(filterValueBase);

    const filterParams = {
        filter1name,
        filter2name,
        filter3name,
        filter1List,
        filter2List,
        filter3List,
        setFilter1List,
        setFilter2List,
        setFilter3List,
    }

    const filterBase = {
        start: startDateString,
        end: yesterdayProp,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    const [filters, setFilters] = useState(filterBase);

    const start = filters.start
    const end = filters.end
    let filter1Filter, filter1Operator;

    if (filters[filter1name] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name].length === 0) {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else if (filters[filter1name][0] === 'Total') {
        filter1Filter = ['null']
        filter1Operator = ['notEquals']
    } else {
        filter1Filter = filter1List
        filter1Operator = ['equals']
    }

    let filter2Filter, filter2Operator;

    if (filters[filter2name] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name].length === 0) {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else if (filters[filter2name][0] === 'Total') {
        filter2Filter = ['null']
        filter2Operator = ['notEquals']
    } else {
        filter2Filter = filter2List
        filter2Operator = ['equals']
    }

    let filter3Filter, filter3Operator;

    if (filters[filter3name] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name].length === 0) {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    } else if (filters[filter3name][0] === 'Total') {
        filter3Filter = ['null']
        filter3Operator = ['notEquals']
    }  else {
        filter3Filter = filter3List
        filter3Operator = ['equals']
    }


    const propObjectBase = {
        data: [],
        start: startDateString,
        end: yesterdayProp,
        theme: theme,
        [filter1name]: "Total",
        [filter2name]: 'Total',
        [filter3name]: "Total",
    };

    
      const [propObject, setpropObject] = useState(propObjectBase);

      const handleStateChange = (prop) => {

        setpropObject(prop);
  
        const filterUpdate = {
            start: prop.start,
            end: prop.end,
            [filter1name]: prop[filter1name],
            [filter2name]: prop[filter2name],
            [filter3name]: prop[filter3name],
          };
  
        setFilters(filterUpdate)

        if (prop.start !== startDateString) {
        setFiltered(true)}
  
      };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Droid Sans', 'Electrolize']
            }
        });
    }, []);

    const cubeFilters = [
        {
            "member": `${cubeTable}.${filter1name}`,
            "operator": `${filter1Operator}`,
            "values": filter1Filter
        },
        {
            "member": `${cubeTable}.${filter2name}`,
            "operator": `${filter2Operator}`,
            "values": filter2Filter
        },
        {
            "member": `${cubeTable}.${filter3name}`,
            "operator": `${filter3Operator}`,
            "values": filter3Filter
        }
    ]
  
  
    const cubeQuery = CubeCampaign(cubeFilters, cubeTable, start, end, 'campaign')
    const { resultSet } = useCubeQuery(cubeQuery);
    const dimQuery = CubeDailyFunction (cubeTable, start, end, filter1name, filter2name, filter3name, cubeFilters)
    const dimResult = useCubeQuery(dimQuery)
   
  
    if (!resultSet) {
        return (
            <LoadingComponent theme={theme} />
        );
    }
  
    if (dimResult.isLoading === true) {
        return (
            <LoadingComponent theme={theme} />
        );
    }
  
  
    const cubeResponse = resultSet.loadResponse.results[0].data
    const dimData = dimResult.resultSet.loadResponses[0].data

    function filterDataByTimestampRange(data, start, end) {
        return data.filter(item => {
            const itemTimestamp = moment(item[`${cubeTable}.timestamp`]).tz('Europe/Malta');
            const startDate = moment(start).tz('Europe/Malta');
            const endDate = moment(end).tz('Europe/Malta');
            return itemTimestamp >= startDate && itemTimestamp <= endDate;
        });
    }

    let dailyFilter, campaignFilter, brandFilter;

    const dailyAgg = ParseDaily(cubeResponse, 'date', cubeTable, 'date')
    const weeklyAgg = ParseDaily(cubeResponse, 'date', cubeTable, 'weekly')
    const monthlyAgg = ParseDaily(cubeResponse, 'date', cubeTable, 'monthly')
    const campaignAgg = ParseDaily(cubeResponse, 'campaign', cubeTable, 'date')
    const brandAgg = ParseDaily(cubeResponse, 'brand', cubeTable, 'date')

    if (!filtered) {
        const dailyFilterBase = filterDataByTimestampRange(cubeResponse, thisMonthDateString, yesterdayProp)
        const campaignFilterBase = filterDataByTimestampRange(cubeResponse, thisMonthDateString, yesterdayProp)
        const brandFilterBase = filterDataByTimestampRange(cubeResponse, thisMonthDateString, yesterdayProp)

        dailyFilter = ParseDaily(dailyFilterBase, 'date', cubeTable, 'date')
        campaignFilter = ParseDaily(campaignFilterBase, 'campaign', cubeTable, 'date')
        brandFilter = ParseDaily(brandFilterBase, 'brand', cubeTable, 'date')

    } else {
        dailyFilter = dailyAgg
        campaignFilter = campaignAgg
        brandFilter = brandAgg
    }

    const datProp = {
        dailyAgg: dailyFilter,
        weeklyAgg,
        monthlyAgg,
        campaignAgg: campaignFilter,
        brandAgg: brandFilter
    }
  
    const uniqueFilter1 = ParseDimensionsAtt(dimData, cubeTable, filter1name)
    const uniqueFilter2 = ParseDimensionsAtt(dimData, cubeTable, filter2name)
    const uniqueFilter3 = ParseDimensionsAtt(dimData, cubeTable, filter3name)
  
    //const agg_result = ParseBudget(cubeResponse, cubeTable, 'graph')
    const agg_result = cubeResponse.map(obj => {
        const newObj = {};
        for (const key in obj) {
            const newKey = key.replace(cubeTable + ".", "");
            newObj[newKey] = obj[key];
        }
        return newObj;
    });

    const updateVar = `${cubeTable}.updated_at`
    const lastUpdated = dimData?.[0]?.[updateVar] || 'loading...';

    if (agg_result.length === 0) {
        return (
            <NoDataContainer
                theme={theme}
            />
        )
    }


    if (lastUpdated) {
        setLastUpdated(lastUpdated)
    }

    const agg_result_final = agg_result.map((obj) => {
        
        let campaign;
        
        if (!obj.campaign) {
            campaign = "null"
        } else if ((obj.campaign).length > 30) {
            campaign = (obj.campaign).slice(0,40)+"..."
        } else { campaign = obj.campaign}

        const ftds = obj.ftds;
        const spend = parseFloat((obj.spend)) || 0;
        const cpa = spend === 0 || ftds === 0 || isNaN(ftds) ? 0 : spend / ftds;
        return {
            ...obj,
            spend: spend,
            cpa: parseInt(cpa),
            campaign: campaign
        };
    });
  
    const dataProp = {
        aggResult : agg_result_final,
        uniqueFilter1,
        uniqueFilter2,
        uniqueFilter3,
    }

    return (
        <View style={styles.container}>

            <View style={{width : '100%' , height : '100%'}}>
            <CampaignSummaryView
                style={{flex : 1 }}
                theme={theme}
                dataProp={datProp}
                propObject={propObject}
                handleStateChange={handleStateChange}
                setHomeTitle={homeTitle}
                agg_result={agg_result_final}
                currentLevel={agg_result_final}
                currentChannel={currentChannel}
                filterParams={filterParams}
                dataPropCamp={dataProp}
                filtered={filtered}
                setFilterList={setFilterList}
                filterList={filterList}
                client={client}
                currentMetrics={currentMetrics}
            />
            </View>           
        </View>

    )

}

export default CampaignSummaryPanel

const styles = StyleSheet.create({
    container: {
        borderWidth: 0,
        borderColor: 'blue',
        minHeight: windowHeight * 0.9,
        height: '100%',
        width: '100%',
        flexDirection: 'column',
        fontFamily: 'Electrolize',
    },
    lineChart: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        padding: '1%',
        fontFamily: 'Electrolize'
    }
})