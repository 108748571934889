import './App.css';
import React, { useState, createContext } from 'react';
import { Dimensions } from 'react-native';
import Login from './Screens/Login';
import SignedUp from './Screens/SignedUp';
import MobileScreen from './Mobile/MobileScreen';
import { themes } from './themes'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import AppRoutes from './Routes';
import { useAuth0 } from "@auth0/auth0-react";
import LoadingComponent from './Components/Loader'
import TagManager from 'react-gtm-module';
import map_1001 from './ClientRoutes/1001.json'
import map_1002 from './ClientRoutes/1002.json'
import map_1003 from './ClientRoutes/1003.json'
import map_1004 from './ClientRoutes/1004.json'
import map_1005 from './ClientRoutes/1005.json'
import map_1006 from './ClientRoutes/1006.json'
import map_1007 from './ClientRoutes/1007.json'
import NotFound from './Components/NoutFound';

const windowWidth = Dimensions.get('window').width;

export const RouteMapContext = createContext()

function App(props) {

  const fmKey = props.fmKey
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [themeType , setTheme] = useState((localStorage.getItem('mode')) ?? 'white')
  const [toolTips , setToolTips] = useState((localStorage.getItem('mode')) ?? true)
  const [clientID, setClientID] = useState((localStorage.getItem('client')))
  const [clientMapID, setClientMapID] = useState((localStorage.getItem('clientId')))

  function tootTipHandler (props) {
    localStorage.setItem("toolTips", props)
    setToolTips(false)
  }

  const tooltipArray = {
    toolTips,
    tootTipHandler
  }

  function configHandler (props) {
    localStorage.setItem("mode", props)
    
    setTheme(props)
  }

  function clientHandler (clientId, clientMapId) {
    localStorage.setItem("client", clientId)
    localStorage.setItem("clientId", clientMapId)
    setClientID(clientId)
    setClientMapID(clientMapId)
  }

  const theme = themes[themeType]

  if (isLoading) {
    return <LoadingComponent theme={theme} />;
  }

  if (!isAuthenticated) {
    return <Login theme={theme}/>
  }

  const userProfile = user
  const userRole = userProfile?.stack_user_metadata?.role || 'guest'
  const userClient = userProfile?.stack_user_metadata?.client || 'guest'
  const userClientId = userProfile?.stack_user_metadata?.clientId || 'guest'
  const userName = userProfile?.name || 'guest'
  const userNickname = userProfile?.nickname || 'guest'
  const comparisonURL = userProfile?.stack_user_metadata?.comparison_url || 'guest'

  const support_url = userProfile?.stack_user_metadata?.support_url || 'guest'
  const support_id = userProfile?.stack_user_metadata?.support_id || 'guest'
  const support_key = userProfile?.stack_user_metadata?.support_key || 'guest'

  const clientIdentifier = clientID || userClient;
  const clientMapId = clientMapID || userClientId
  const routeMap = {
    ...map_1001, 
    ...map_1002, 
    ...map_1003, 
    ...map_1004, 
    ...map_1005, 
    ...map_1006, 
    ...map_1007
  }

  
  const routeMapBase = routeMap[clientMapId]
  const currentRoutes = routeMap[clientMapId]['config']
  const currentMetrics = routeMap[clientMapId]['metricConfig']

  const configArray = {
    role : userRole,
    userName,
    userNickname,
    clientID: clientIdentifier,
    comparison_url: comparisonURL,
    clientMapId,
    configHandler,
    clientHandler,
    tooltipArray,
    currentMetrics,
    fmKey,
    support_url,
    support_id,
    support_key
  }

  const tagManagerArgs = {
    gtmId: 'GTM-N956Z6GW',
  }

  TagManager.initialize(tagManagerArgs);

  if (clientIdentifier === "guest") {
    return (<SignedUp theme={theme} configArray={configArray} themeType={themeType} />)
  }

  else if (windowWidth < 450) {
    return (
      <MobileScreen theme={theme} configArray={configArray} themeType={themeType} />
    )
  } else { 
    return (
    <RouteMapContext.Provider value={routeMapBase}>
    <Router >
    <Routes>
    {currentRoutes.map((route, index) => {
          const Component = AppRoutes[route.component];
          return (
            
            <Route 
              key={index}
              path={route.path}
              element={<Component theme={theme} 
                                  configArray={configArray} 
                                  configHandler={configHandler}
                                  clientHandler={clientHandler}
                                  userProfile={userProfile}
                                  themeType={themeType} 
                                  
                                  />}
              exact={route.exact || false}
            />
            
          );
        })}
      <Route path="*" element={<NotFound theme={theme}/>} />
    </Routes>
    </Router>
    </RouteMapContext.Provider>
    )
  }
}

export default App;
