import AWS from "aws-sdk";

export default async function signRequest(event_data, support_url, support_key, support_id, method) {
    const accessKeyId = support_id;
    const secretAccessKey = support_key;
    const url = support_url;

    const { host, pathname: path } = new URL(url);

    // Set up AWS credentials
    const credentials = new AWS.Credentials(accessKeyId, secretAccessKey);

    // Create a request object for signing
    const request = new AWS.HttpRequest(url);
    request.method = method;
    request.path = path || "/";
    request.region = "eu-central-1"; // Set to the Lambda's region
    request.headers["host"] = host;
    request.headers["Content-Type"] = "application/json";
    request.body = JSON.stringify(event_data); // Use an empty body to avoid issues if no payload is needed

    // Initialize the SigV4 signer with the Lambda service
    const signer = new AWS.Signers.V4(request, "lambda"); // Use "lambda" for Lambda function URLs

    // Sign the request
    signer.addAuthorization(credentials, new Date());
    request.region = "eu-central-1";

    return {
        signedUrl: url, // Base URL
        headers: request.headers, // Signed headers including Authorization
      };
}

export async function postToSignedUrl({ signedUrl, headers }, payload, method) {
    try {
      const response = await fetch(signedUrl, {
        method: method,
        headers: {
          ...headers, // Spread the signed headers, including Authorization, X-Amz-Date, etc.
          //"Content-Type": "application/json", // Ensure Content-Type is present
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Request failed with status ${response.status}. AWS response: ${errorText}`);
      }
  
      const data = await response.json();
      return { statusCode: response.status, data };
    } catch (error) {
      console.error("Error posting to Lambda function:", {
        message: error.message,
        stack: error.stack,
        additionalInfo: {
          signedUrl,
          headers,
          payload,
        },
      });
      return { statusCode: "error", data: null };
    }
  }